import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Imovel, Cliente, Response } from '../../_models';
import { NotifierService } from 'angular-notifier';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-update-campanha',
  templateUrl: './update-campanha.component.html',
  styleUrls: ['./update-campanha.component.scss'],
})
export class UpdateCampanhaComponent implements OnInit {
  campanhaForm: FormGroup;
  clientes: Cliente[] = [];

  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];

  origem: any = [];
  tipoImovel;
  tipoPregao;
  modalidade;
  imoveis;

  loading: boolean = false;
  loadingClientes: boolean = false;
  loadingImoveis: boolean = false;

  //mask
  public mask: Array<string | RegExp>;

  bsValue = new Date();
  bsRangeValue: Date[];
  bsValue2 = new Date();
  bsRangeValue2: Date[];
  maxDate = new Date();
  minDate: Date;
  parametrosAgenda = [];
  weekDays = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];
  meses = [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ];

  plataforma = ['Email'];
  templates;
  campanhaId: number;

  private debounce: Subject<string> = new Subject<string>();

  constructor(
    private restangular: Restangular,
    private notifier: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private localeService: BsLocaleService
  ) {
    localeService.use('pt-br');
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
  }

  ngOnInit() {
    this.campanhaId = this.route.snapshot.params['id'];

    this.restangular
      .one('campanha', this.campanhaId)
      .get()
      .subscribe(
        (res) => {
          this.updateForm(res.data);
          this.getImoveis();
          this.getClientes();
          this.onChangeEstadoImovel();
          this.onChangeMunicipioImovel();
        },
        () => {
          this.notifier.notify('Erro', 'Não foi possível carregar a campanha!');
          this.router.navigate['/campanha'];
        }
      );

    this.bsRangeValue = [this.bsValue, this.maxDate];
    this.bsRangeValue2 = [this.bsValue2, this.maxDate];

    this.getModalidade();
    this.getTipoImovel();
    this.getPregao();
    this.getEstadoImovel();
    this.getTemplate();
    this.getOrigem();
  }

  updateForm(campanha) {
    this.campanhaForm = this.fb.group({
      imovel: this.fb.group({
        estado: [campanha.imovel.estadoId],
        municipio: [
          {
            value: campanha.imovel.municipioId,
            disabled:
              !campanha.imovel.municipioId ||
              campanha.imovel.municipioId.length == 0,
          },
        ],
        bairro: [
          {
            value: campanha.imovel.bairroId,
            disabled:
              !campanha.imovel.bairroId || campanha.imovel.bairroId.length == 0,
          },
        ],
        data: [
          campanha.imovel.dataInicial && campanha.imovel.dataFinal
            ? [
                moment.utc(campanha.imovel.dataInicial).local().toDate(),
                moment.utc(campanha.imovel.dataFinal).local().toDate(),
              ]
            : null,
        ],
        imoveisActive: [true],
        valorInicial: [campanha.imovel.valorInicial],
        valorFinal: [campanha.imovel.valorFinal],
        origem: [campanha.imovel.origem],
        pregao: [campanha.imovel.tipoPregao],
        tipo: [campanha.imovel.tipoId],
        modalidade: [campanha.imovel.modalidadeId],
      }),
      cliente: this.fb.group({
        temContrato: [campanha.cliente.temContrato],
        temOportunidade: [campanha.cliente.temOportunidade],
        // usarInteresses: [campanha.cliente.usarInteresses],
      }),
      titulo: [campanha.titulo],
      templateId: [campanha.templateId, Validators.required],
      meioNotificacao: [campanha.meiosNotificacao, Validators.required],
      tipoAgenda: [campanha.tipoAgenda, Validators.required],
      dataExecucao: [
        moment.utc(campanha.dataExecucao).local().toDate(),
        Validators.required,
      ],
      dataEncerramento: [
        campanha.dataEncerramento
          ? moment.utc(campanha.dataEncerramento).local().toDate()
          : null,
      ],
      parametrosAgenda: [campanha.parametrosAgenda],
    });

    this.subscribeToValueChanges();

    this.parametrosAgenda = campanha.parametrosAgenda;
  }

  subscribeToValueChanges() {
    this.campanhaForm
      .get('imovel')
      .valueChanges.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.imoveis = [];
        this.getImoveis();
      });
    this.campanhaForm
      .get('cliente')
      .valueChanges.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.clientes = [];
        this.getClientes();
      });

    this.campanhaForm.get('tipoAgenda').valueChanges.subscribe(() => {
      this.parametrosAgenda = [];
    });
  }

  groupByMunicipio = (item) => item.municipio.nome;
  groupValueFn = (_: string, children: any[]) => ({
    name: children[0].municipio.nome,
  });

  getClientes() {
    this.loadingClientes = true;
    this.restangular
      .one('campanha/QtdClientes')
      .get({
        temContrato: this.campanhaForm.get('cliente.temContrato').value || '',
        temOportunidade:
          this.campanhaForm.get('cliente.temOportunidade').value || '',
      })
      .subscribe(
        (response: Response<Cliente[]>) => {
          this.clientes = response.data;
          this.loadingClientes = false;
        },
        () => {
          this.notifier.notify(
            'error',
            'Não foi possível carregar os clientes'
          );
          this.loadingClientes = false;
        }
      );
  }

  getImoveis() {
    this.loadingImoveis = true;
    const formImovel = this.campanhaForm.get('imovel') as FormGroup;

    let dataInicial = '';
    let dataFinal = '';

    const datas = formImovel.controls.data.value;
    if (datas !== null) {
      dataInicial = moment(datas[0]).toISOString();
      dataFinal = moment(datas[1]).toISOString();
    }

    this.restangular
      .one('campanha/QtdImoveis')
      .get({
        bairros: formImovel.get('bairro').value || [],
        estado: formImovel.get('estado').value || '',
        municipio: formImovel.get('municipio').value || [],
        origem: formImovel.get('origem').value || [],
        ImoveisActive: formImovel.get('imoveisActive').value,
        valorFinal: formImovel.get('valorFinal').value || '',
        valorInicial: formImovel.get('valorInicial').value || '',
        tipo: formImovel.get('tipo').value || [],
        modalidade: formImovel.get('modalidade').value || [],
        pregao: formImovel.get('pregao').value || [],
        dataInicial,
        dataFinal,
      })
      .subscribe(
        (res) => {
          this.imoveis = res.data;
          this.loadingImoveis = false;
        },
        () => {
          this.loading = false;
          this.loadingImoveis = false;
        }
      );
  }

  getOrigem() {
    this.restangular
      .one('imovel/origem')
      .get()
      .subscribe((res) => {
        this.origem = res.data;
      });
  }

  getModalidade() {
    this.restangular
      .one('modalidade')
      .get()
      .subscribe((res) => {
        this.modalidade = res.data;
      });
  }

  getPregao() {
    this.restangular
      .one('pregao')
      .get()
      .subscribe((res) => {
        this.tipoPregao = res.data;
      });
  }

  getTipoImovel() {
    this.restangular
      .one('imovel/tipo')
      .get()
      .subscribe((res) => {
        this.tipoImovel = res.data;
      });
  }

  getEstadoImovel() {
    this.restangular
      .one('localidade/estado')
      .get()
      .subscribe((res) => {
        this.estados = res.data;
      });
  }

  getTemplate() {
    this.restangular
      .one('template')
      .get()
      .subscribe((res) => {
        this.templates = res.data;
      });
  }

  onChangeEstadoImovel() {
    const formImovel = this.campanhaForm.get('imovel') as FormGroup;
    this.municipios = [];
    if (formImovel.value.estado && formImovel.value.estado != '') {
      this.restangular
        .one('localidade/municipio')
        .get({ estados: formImovel.value.estado })
        .subscribe((municipios) => {
          this.municipios = municipios.data;
          formImovel.get('municipio').enable();
          formImovel
            .get('municipio')
            .patchValue(formImovel.get('municipio').value);
        });
      //formImovel.get('bairro').patchValue(null);
    }
  }

  onChangeMunicipioImovel() {
    const formImovel = this.campanhaForm.get('imovel') as FormGroup;
    this.bairros = [];
    const municipios = formImovel.get('municipio').value;

    if (municipios && municipios != '') {
      this.restangular
        .one('localidade/bairro')
        .get({ municipios })
        .subscribe((res) => {
          const bairros = res.data;
          this.bairros = bairros;
          formImovel.get('bairro').enable();
          formImovel.get('bairro').patchValue(formImovel.get('bairro').value);
        });
    }
  }

  selecionaParametroAgenda(item) {
    if (this.parametrosAgenda.includes(item)) {
      const index = this.parametrosAgenda.indexOf(item);
      this.parametrosAgenda.splice(index, 1);
    } else {
      this.parametrosAgenda.push(item);
    }
    this.campanhaForm.get('parametrosAgenda').setValue(this.parametrosAgenda);
  }

  existeParametroAgenda(item) {
    return this.parametrosAgenda.includes(item);
  }

  verificarPlataforma(plataforma) {
    return this.campanhaForm.get('meioNotificacao').value.includes(plataforma);
  }

  onSubmit() {
    console.log(this.campanhaForm);
    if (!this.campanhaForm.valid) {
      Object.keys(this.campanhaForm.controls).forEach((campo) => {
        const controle = this.campanhaForm.get(campo);
        controle.markAsTouched();
      });
      this.notifier.notify('error', 'Preencha todos os campos obrigatórios');
      return;
    }
    const campanha = this.campanhaForm;

    let dataInicial = null;
    let dataFinal = null;

    const datas = campanha.get('imovel.data').value;
    if (datas !== null) {
      dataInicial = moment(datas[0]).toISOString();
      dataFinal = moment(datas[1]).toISOString();
    }

    this.restangular
      .one('campanha')
      .customPUT({
        campanha: {
          campanhaId: this.campanhaId,
          cliente: {
            temContrato: JSON.parse(campanha.get('cliente.temContrato').value),
            temOportunidade: JSON.parse(
              campanha.get('cliente.temOportunidade').value
            ),
            // usarInteresses: JSON.parse(
            //   campanha.get('cliente.usarInteresses').value
            // ),
          },
          dataEncerramento: campanha.get('dataEncerramento').value,
          dataExecucao: campanha.get('dataExecucao').value,
          imovel: {
            estadoId: campanha.get('imovel.estado').value,
            dataInicial,
            dataFinal,
            imoveisActive: true,
            valorInicial: campanha.get('imovel.valorInicial').value,
            valorFinal: campanha.get('imovel.valorFinal').value,
            municipioId: campanha.get('imovel.municipio').value,
            bairroId: campanha.get('imovel.bairro').value,
            origem: campanha.get('imovel.origem').value,
            tipoId: campanha.get('imovel.tipo').value,
            pregaoId: campanha.get('imovel.pregao').value,
            modalidadeId: campanha.get('imovel.modalidade').value,
          },
          titulo: campanha.get('titulo').value,
          meiosNotificacao: campanha.get('meioNotificacao').value,
          parametrosAgenda: campanha.get('parametrosAgenda').value,
          templateId: campanha.get('templateId').value,
          tipoAgenda: parseInt(campanha.get('tipoAgenda').value, 10),
        },
      })
      .subscribe(
        () => {
          this.notifier.notify('success', 'Campanha atualizada com sucesso');
          this.router.navigate(['/campanha']);
        },
        () => {
          this.notifier.notify('error', 'Erro ao atualizar campanha');
        }
      );
  }

  formatPhoneNumber(phone: string): string {
    let phoneTratado = phone.replace(/\D/g, '');

    if (!phone.startsWith('5') && phoneTratado.length < 13)
      phoneTratado = '55' + phoneTratado;

    return phoneTratado;
  }
}
