import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'app-create-statusIntencaoCompra',
  templateUrl: './create-status-intencao-compra.component.html',
  styleUrls: ['./create-status-intencao-compra.component.scss'],
})
export class CreateStatusIntencaoCompraComponent implements OnInit {
  formulario: FormGroup;
  grupos: any[] = [];

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.restAngular
      .one('GrupoStatus')
      .get()
      .subscribe(
        (res) => {
          this.grupos = res.data;
        },
        (err) => {
          this.notifier.notify('error', 'Erro ao obter grupos');
        }
      );

    this.formulario = this.formBuilder.group({
      descricao: ['', Validators.required],
      grupoId: [null, Validators.required],
      possuiDataLimite: [false, Validators.required],
    });
  }

  onSubimit() {
    this.restAngular
      .all('statusIntencaoCompra')
      .post(this.formulario.value)
      .subscribe(
        () => {
          this.notifier.notify('success', 'Evento cadastrado com sucesso');
          this.router.navigate(['/eventos']);
        },
        () => {
          this.notifier.notify('error', 'Erro ao cadastrar evento');
        }
      );
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) };
  }
}
