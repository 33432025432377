import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Restangular } from 'ngx-restangular';
import { TipoPessoa } from 'app/views/_models';

@Component({
  selector: 'app-create-corretor',
  templateUrl: './create-corretor.component.html',
  styleUrls: ['./create-corretor.component.scss']
})
export class CreateCorretorComponent implements OnInit {

  formulario: FormGroup;
  tiposPessoa: TipoPessoa[] = [];

  public maskCnpj: Array<string | RegExp>
  public maskCpf: (string | RegExp)[];

  constructor(private restAngular: Restangular, private notifier: NotifierService, private router: Router, private formBuilder: FormBuilder) 
  { 
    this.maskCpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,];
    this.maskCnpj = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/,];
  }

  ngOnInit() {

    this.restAngular.one('cliente/tipoPessoa').get().subscribe(
      (tiposPessoa) => {
        this.tiposPessoa = tiposPessoa.data;
      }
    );

    this.formulario = this.formBuilder.group({
      tipoPessoaId: [1],
      nome: ['', Validators.required],
      cpfCnpj: [''],
      celular: [''],
      email: [''],
      banco: [''],
      agencia: [''],
      conta: [''],
      chavePix: [''],
    });
  }

  onSubimit(){
    this.restAngular.all('corretor').post(this.formulario.value).subscribe(
      () => {
        this.notifier.notify('success', 'Corretor cadastrado com sucesso');
        this.router.navigate(['/corretores']);
      },
      () => {
        this.notifier.notify('error', 'Erro ao cadastrar corretor');
      }
    );
  }

  verificaValidTouched(campo) {
    return !this.formulario.get(campo).valid && this.formulario.get(campo).touched;
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) }
  }
}
