var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Restangular } from 'ngx-restangular';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
var EditAnaliseLeilaoComponent = /** @class */ (function () {
    function EditAnaliseLeilaoComponent(restangular, router, formBuilder, notifierService, route, localeService) {
        this.restangular = restangular;
        this.router = router;
        this.formBuilder = formBuilder;
        this.notifierService = notifierService;
        this.route = route;
        this.localeService = localeService;
        this.loading = false;
        this.fileToUpload = null;
        this.arrayFotos = [];
        //localidade
        this.estados = [];
        this.municipios = [];
        this.bairros = [];
        this.editorConfig = {
            editable: true,
            spellcheck: true,
            height: 'auto',
            minHeight: '0',
            maxHeight: '300px',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: true,
            showToolbar: true,
            placeholder: 'Descrição detalhada...',
            defaultParagraphSeparator: '',
            defaultFontName: '',
            defaultFontSize: '',
            fonts: [
                { class: 'roboto', name: 'Roboto' },
                { class: 'arial', name: 'Arial' },
                { class: 'times-new-roman', name: 'Times New Roman' },
                { class: 'calibri', name: 'Calibri' },
                { class: 'comic-sans-ms', name: 'Comic Sans MS' },
            ],
            sanitize: true,
        };
        localeService.use('pt-br');
        this.id = this.route.snapshot.params.id;
    }
    EditAnaliseLeilaoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.restangular
            .one('analiseLeilao', this.id)
            .get()
            .subscribe(function (res) {
            var analise = res.data;
            // let fotos = analise.fotos;
            // if (fotos) {
            //   fotos = JSON.parse(fotos).fotos;
            // } else {
            //   fotos = [];
            // }
            _this.formulario = _this.formBuilder.group({
                analiseLeilaoId: [_this.id],
                link: [analise.link],
                fotos: [analise.fotos],
                titulo: [analise.titulo.toUpperCase()],
                edital: [analise.edital],
                descricao: [analise.descricao],
                numeroProcesso: [analise.numeroProcesso],
                linkMaps: [analise.linkMaps],
                origem: [analise.origem],
                tipoId: [analise.tipoId],
                dataPrimeiraPraca: [
                    analise.dataPrimeiraPraca
                        ? moment.utc(analise.dataPrimeiraPraca).local().toDate()
                        : null,
                ],
                dataSegundaPraca: [
                    analise.dataSegundaPraca
                        ? moment.utc(analise.dataSegundaPraca).local().toDate()
                        : null,
                ],
                modalidadeId: [analise.modalidadeId],
                valorPrimeiraPraca: [analise.valorPrimeiraPraca],
                valorSegundaPraca: [analise.valorSegundaPraca],
                bairroId: [analise.bairroId],
                municipioId: [analise.municipioId],
                estadoId: [analise.estadoId],
                endereco: [analise.endereco]
            });
            _this.formulario.get('titulo').valueChanges.subscribe(function (value) {
                if (value) {
                    _this.formulario
                        .get('titulo')
                        .setValue(value.toUpperCase(), { emitEvent: false });
                }
            });
            _this.restangular.one('modalidade').get()
                .subscribe(function (res) { return _this.modalidade = res.data; });
            _this.restangular.one('tipo').get()
                .subscribe(function (res) { return _this.tipo = res.data; });
            _this.restangular
                .one('localidade/estado')
                .get()
                .subscribe(function (estados) {
                _this.estados = estados.data;
                if (analise.bairroId) {
                    _this.formulario
                        .get('estadoId')
                        .setValue(analise.bairro.municipio.estado.estadoId);
                    _this.formulario
                        .get('municipioId')
                        .setValue(analise.bairro.municipio.municipioId);
                }
                _this.onChangeEstado();
                _this.onChangeMunicipio();
            });
        });
    };
    EditAnaliseLeilaoComponent.prototype.onChangeEstado = function () {
        var _this = this;
        var estadoId = this.formulario.value.estadoId;
        if (!estadoId)
            this.formulario.get('municipioId').disable();
        if (this.formulario.value.estadoId === null) {
            this.formulario.get('municipioId').disable();
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restangular
            .one('localidade/municipio')
            .get({ estadoId: this.formulario.value.estadoId })
            .subscribe(function (municipios) {
            _this.municipios = municipios.data;
            _this.formulario.get('municipioId').enable();
        });
    };
    EditAnaliseLeilaoComponent.prototype.onChangeMunicipio = function () {
        var _this = this;
        var municipioId = this.formulario.value.municipioId;
        if (municipioId === null) {
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restangular
            .one('localidade/bairro')
            .get({ municipioId: municipioId })
            .subscribe(function (bairros) {
            _this.bairros = bairros.data;
            _this.formulario.get('bairroId').enable();
        });
    };
    EditAnaliseLeilaoComponent.prototype.removeEdital = function () {
        this.formulario.get('edital').setValue('');
        this.inputEdtial.nativeElement.value = '';
        this.inputEdtial.nativeElement.click();
    };
    EditAnaliseLeilaoComponent.prototype.alterarFoto = function (i) {
        this.numeroAdcFoto = i;
        this.inputFotos.nativeElement.click();
    };
    EditAnaliseLeilaoComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.formulario.valid) {
            this.loading = true;
            var body = this.formulario.value;
            // body.fotos = JSON.stringify({ fotos: body.fotos });
            //body.analiseLeilaoId = this.id;
            this.restangular
                .one('analiseLeilao')
                .customPUT(body)
                .subscribe(function (response) {
                _this.loading = false;
                _this.notifierService.notify('success', 'Registro de leilão atualizada com sucesso!');
                _this.router.navigate(['/analise-imovel']);
            });
        }
        else {
            this.verificaValidacoesForm(this.formulario);
        }
    };
    EditAnaliseLeilaoComponent.prototype.verificaValidacoesForm = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (campo) {
            var controle = formGroup.get(campo);
            controle.markAsTouched();
            if (controle instanceof FormGroup) {
                _this.verificaValidacoesForm(controle);
            }
        });
    };
    EditAnaliseLeilaoComponent.prototype.uploadFileRecoverUrl = function (body) {
        return this.restangular
            .all('arquivo')
            .post(body)
            .pipe(map(function (response) {
            return response.data.url;
        }));
    };
    EditAnaliseLeilaoComponent.prototype.fileChangeEvent = function (fileInput) {
        var _this = this;
        this.imageError = null;
        var arrayImagens = fileInput.target.files.length;
        for (var i = 0; i < arrayImagens; i++) {
            this.arrayFotos.push(fileInput.target.files[i]);
        }
        this.arrayFotos.forEach(function (x) {
            if (x) {
                // Size Filter Bytes
                var max_size = 5242880;
                var allowed_types = ['image/png', 'image/jpeg'];
                var max_height_1 = 15200;
                var max_width_1 = 25600;
                if (x.size > max_size) {
                    _this.imageError = 'Maximum size allowed is 5Mb';
                    _this.arrayFotos = [];
                    return false;
                }
                var reader = new FileReader();
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function (rs) {
                        var img_height = rs.currentTarget['height'];
                        var img_width = rs.currentTarget['width'];
                        _this.arrayFotos = [];
                        if (img_height > max_height_1 && img_width > max_width_1) {
                            _this.imageError =
                                'Maximum dimentions allowed ' +
                                    max_height_1 +
                                    '*' +
                                    max_width_1 +
                                    'px';
                            return false;
                        }
                        else {
                            var imgBase64Path = e.target.result;
                            var arquivo = {
                                url: imgBase64Path,
                                nome: x.name,
                                base64: imgBase64Path,
                                tipo: x.type,
                                folder: 'fotos-imob',
                            };
                            _this.arrayFotos = [];
                            _this.atualizarFoto(arquivo);
                        }
                    };
                };
                reader.readAsDataURL(x);
            }
        });
    };
    EditAnaliseLeilaoComponent.prototype.editalChangeEvent = function (editalInput) {
        return __awaiter(this, void 0, void 0, function () {
            var reader;
            var _this = this;
            return __generator(this, function (_a) {
                this.fileToUpload = editalInput.target.files[0];
                this.fileToUpload.name;
                this.fileToUpload.size;
                this.fileToUpload.type;
                reader = new FileReader();
                reader.readAsDataURL(this.fileToUpload);
                reader.onload = function () {
                    _this.editalbase64 = reader.result;
                    var arquivo = {
                        nome: _this.fileToUpload.name,
                        base64: _this.editalbase64,
                        tipo: _this.fileToUpload.type,
                        folder: 'anexos-imob',
                    };
                    _this.uploadFileRecoverUrl(arquivo).subscribe(function (url) {
                        _this.formulario.get('edital').setValue(url);
                    });
                };
                return [2 /*return*/];
            });
        });
    };
    EditAnaliseLeilaoComponent.prototype.atualizarFoto = function (obj) {
        var fotos = this.formulario.get('fotos').value;
        this.uploadFileRecoverUrl(obj).subscribe(function (url) {
            fotos.push(url);
        });
    };
    EditAnaliseLeilaoComponent.prototype.deleteFoto = function (indexFoto) {
        var fotos = this.formulario.controls['fotos'];
        fotos.value.splice(indexFoto, 1);
    };
    EditAnaliseLeilaoComponent.prototype.verificaValidTouched = function (campo) {
        if (!this.formulario.get(campo).disabled) {
            return (!this.formulario.get(campo).valid && this.formulario.get(campo).touched);
        }
        return false;
    };
    EditAnaliseLeilaoComponent.prototype.verificaValidList = function (campoArray, campo, i) {
        var lista = this.formulario.get(campoArray);
        var item = lista.controls[i];
        return !item.get(campo).valid;
    };
    EditAnaliseLeilaoComponent.prototype.aplicaCssErro = function (campo) {
        return { 'has-error': this.verificaValidTouched(campo) };
    };
    EditAnaliseLeilaoComponent.prototype.aplicaCssErroLista = function (campoArray, campo, i) {
        return { 'has-error': this.verificaValidList(campoArray, campo, i) };
    };
    return EditAnaliseLeilaoComponent;
}());
export { EditAnaliseLeilaoComponent };
