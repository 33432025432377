import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ConsultaCepService } from 'app/views/usuarios/shared/consulta-cep/consulta-cep.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'app-create-contato',
  templateUrl: './create-contato.component.html',
  styleUrls: ['./create-contato.component.scss'],
})
export class CreateContatoComponent implements OnInit {
  formulario: FormGroup;
  id: number;
  loadorigem = false;
  origemcontato = [];

  //masks
  public maskTelefone: Array<string | RegExp>;
  public maskCep: Array<string | RegExp>;
  public maskCnpj: Array<string | RegExp>;
  public maskCpf: (string | RegExp)[];
  public maskRg: (string | RegExp)[];

  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];

  hasLocalidadeInteresse = this.formBuilder.control(true);
  hasEndereco = this.formBuilder.control(false);
  bairrosAgrupados: { label: string; children: any }[];

  constructor(
    private formBuilder: FormBuilder,
    private restangular: Restangular,
    private notifierService: NotifierService,
    private router: Router,
    private cepService: ConsultaCepService
  ) {
    this.maskTelefone = [
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
    this.maskCep = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.maskCpf = [
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
    this.maskCnpj = [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
    this.maskRg = [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
    ];
  }

  ngOnInit() {
    this.getOrigemContato();
    this.formulario = this.formBuilder.group({
      nome: ['', Validators.required],
      email: [null],
      telefone: [null],
      mensagem: [null],
      origemId: [null],
      comoContactar: [null],
      localidadesInteresse: this.formBuilder.array([]),
      valorInicial: [null],
      valorFinal: [null],
      bairroId: [null],
      municipioId: [null],
      estadoId: [null],
    });

    this.restangular
      .one('localidade/estado')
      .get()
      .subscribe((estados) => {
        this.estados = estados.data;
        this.formulario.get('municipioId').disable();
        this.formulario.get('bairroId').disable();
      });
  }

  onSubimit() {
    if (this.formulario.invalid) {
      this.notifierService.notify('error', 'Formulário inválido');
      return;
    }

    const body = {
      ...this.formulario.value,
      localidadesInteresse: this.formulario.value.localidadesInteresse.map(
        (x) => x.bairroId
      ),
    };

    this.restangular
      .all('contato')
      .post(body)
      .subscribe(
        () => {
          this.notifierService.notify(
            'success',
            'Contato atualizado com sucesso'
          );
          this.router.navigate(['contato']);
        },
        (error) => {
          this.notifierService.notify('error', 'Erro ao criar contato');
        }
      );
  }

  onChangeEstado() {
    this.formulario.get('municipioId').disable();

    if (this.formulario.value.estadoId === null) {
      this.formulario.get('municipioId').disable();
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/municipio')
      .get({ estadoId: this.formulario.value.estadoId })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.formulario.get('municipioId').enable();
      });
  }

  onChangeMunicipio() {
    const municipioId = this.formulario.value.municipioId;

    if (municipioId === null) {
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/bairro')
      .get({ municipioId })
      .subscribe((res) => {
        const bairros = res.data;

        // Organizar os bairros por zona usando reduce
        const bairrosPorZona = bairros.reduce((acc, bairro) => {
          const zona = bairro.zona || 'Sem Zona';

          if (!acc[zona]) {
            acc[zona] = [];
          }
          acc[zona].push({ bairroId: bairro.bairroId, nome: bairro.nome });
          return acc;
        }, {});

        // Converter para o formato desejado
        const bairrosFormatados = Object.keys(bairrosPorZona).map((zona) => {
          return {
            label: zona,
            children: bairrosPorZona[zona],
          };
        });

        // Atualizar os bairros organizados por zona
        this.bairrosAgrupados = bairrosFormatados;
        this.bairros = bairros;
        this.formulario.get('bairroId').enable();
      });
  }

  addLocalidadeInteresse() {
    const localidadesInteresse = this.formulario.get(
      'localidadesInteresse'
    ) as FormArray;

    if (
      localidadesInteresse.controls.filter(
        (x) => x.value.bairroId === this.formulario.value.bairroId
      ).length > 0
    ) {
      this.notifierService.notify('error', 'Localidade já adicionada');
      return;
    }

    localidadesInteresse.push(
      this.formBuilder.group({
        bairroId: [this.formulario.value.bairroId],
        bairro: [
          this.bairros.find(
            (bairro) => bairro.bairroId === this.formulario.value.bairroId
          ).nome,
        ],
        municipio: [
          this.municipios.find(
            (municipio) =>
              municipio.municipioId === this.formulario.value.municipioId
          ).nome,
        ],
        estado: [
          this.estados.find(
            (estado) => estado.estadoId === this.formulario.value.estadoId
          ).nome,
        ],
      })
    );

    this.formulario.get('bairroId').reset();
  }

  removeLocalidadeInteresse(index) {
    const localidadesInteresse = this.formulario.get(
      'localidadesInteresse'
    ) as FormArray;
    localidadesInteresse.removeAt(index);
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) };
  }

  consultaCEP() {
    const cep = this.formulario.get('endereco.cep').value;

    if (cep != null && cep !== '') {
      this.cepService
        .consultaCEP(cep)
        .subscribe((dados) => this.populaDadosForm(dados));
    }
  }

  addEndereco() {
    if (this.hasEndereco) {
      this.formulario.addControl(
        'endereco',
        this.formBuilder.group({
          enderecoId: [0],
          cep: [''],
          numero: [''],
          complemento: [''],
          bairro: [''],
          cidade: [''],
          estado: [''],
          logradouro: [''],
        })
      );
    } else {
      this.formulario.removeControl('endereco');
    }
  }

  populaDadosForm(dados: any) {
    this.formulario.patchValue({
      endereco: {
        logradouro: dados.logradouro,
        complemento: dados.complemento,
        bairro: dados.bairro,
        cidade: dados.localidade,
        estado: dados.uf,
      },
    });
  }

  getOrigemContato() {
    this.loadorigem = true;
    this.restangular
      .one('origemcontato')
      .get()
      .subscribe(
        (response) => {
          this.origemcontato = response.data;
          this.loadorigem = false;
        },
        (error) => {
          this.notifierService.notify(
            'error',
            'Não foi possível carregar as origens'
          );
          this.loadorigem = false;
        }
      );
  }
}
