<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <p class="d-inline">
            <i class="fas fa-home"></i>
            &nbsp;Imoveis
          </p>
          <button
            type="button"
            class="btn btn-success float-right"
            [routerLink]="['/create-imovel']"
          >
            <i class="fa fa-plus-circle"></i>&nbsp; Novo Imóvel
          </button>

          <button
            class="btn btn-primary float-right mr-3"
            tooltip="Exportar para excel"
            (click)="downloadRelatorio('EXCEL')"
            *ngIf="!exportando"
          >
            <i class="fas fa-file-excel"></i> Exportar
          </button>
          <button class="btn btn-primary float-right mr-3" *ngIf="exportando">
            <i class="fas fa-spin fa-spinner"></i> Exportando...
          </button>
        </div>
        <div class="card-block">
          <div class="row mb-4" *ngIf="imoveis">
            <div class="col-10 text-muted">
              Encontrados {{ imoveis.length }} Imóveis
            </div>
            <div class="col-2">
              <button
                style="position: relative"
                class="btn btn-primary float-right mr-2"
                (click)="openModal(abrirFiltros)"
              >
                <i class="fas fa-filter"></i> Filtros
                <span
                  *ngIf="filtrosAplicados > 0"
                  class="badge badge-pill badge-danger numFiltros"
                  >{{ filtrosAplicados }}</span
                >
              </button>
            </div>
          </div>
          <div class="table-responsive mt-1">
            <table
              class="table table-striped"
              [mfData]="imoveis"
              #mfTable="mfDataTable"
              [mfRowsOnPage]="10"
            >
              <thead>
                <tr>
                  <th>
                    <mfDefaultSorter by="imovelId">Referência</mfDefaultSorter>
                  </th>
                  <th>
                    <mfDefaultSorter by="tiulo">Titulo</mfDefaultSorter>
                  </th>
                  <th>
                    <mfDefaultSorter by="tipo.tipoId">Tipo</mfDefaultSorter>
                  </th>
                  <th>
                    <mfDefaultSorter by="bairro.zona">Zona</mfDefaultSorter>
                  </th>
                  <th>
                    <mfDefaultSorter by="bairro.nome">Bairro</mfDefaultSorter>
                  </th>
                  <th>
                    <mfDefaultSorter by="dataCadastro"
                      >Data Cadastro</mfDefaultSorter
                    >
                  </th>
                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of mfTable.data">
                  <td>{{ item.imovelId }}</td>
                  <td>
                    <a [href]="item.linkOrigem" target="_blank">
                      {{ item.titulo | uppercase }}</a
                    >
                  </td>
                  <td>{{ item.tipo.descricao }}</td>
                  <td>{{ item.bairro?.zona }}</td>
                  <td>
                    {{
                      item.bairro
                        ? item.bairro.nome +
                          " - " +
                          item.bairro.municipio.nome +
                          " - " +
                          item.bairro.municipio.estado.sigla
                        : ""
                    }}
                  </td>
                  <td nowrap>
                    {{
                      item.dataCadastro
                        | amUtc
                        | amLocal
                        | amDateFormat : "DD/MM/YYYY HH:mm"
                    }}
                  </td>

                  <td class="text-center" nowrap>
                    <a
                      [routerLink]="['/update-imovel', item.imovelId]"
                      tooltip="Editar"
                      class="mr-3 pointer"
                      ><i class="fa-lg far fa-edit"></i
                    ></a>
                    <a
                      (click)="delete(item.imovelId)"
                      tooltip="Excluir"
                      class="mr-1 pointer"
                      ><i class="fa-lg far fa-times-circle text-danger"></i
                    ></a>
                  </td>
                </tr>
                <tr *ngIf="loading">
                  <td class="text-center text-muted" colspan="7">
                    <h5>
                      <i class="fa fa-spin fa-spinner"></i>&nbsp;Carregando...
                    </h5>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="7">
                    <mfBootstrapPaginator
                      [rowsOnPageSet]="[5, 10, 25]"
                    ></mfBootstrapPaginator>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #abrirFiltros>
  <div class="modal-header bg-primary">
    <h5 class="modal-title" id="filtrosModalLabel">
      <i class="fas fa-filter"></i> Filtros
    </h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="container-fluid">
    <div class="modal-body filtro" [formGroup]="filtro">
      <div class="row mb-2">
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >1ª Praça</span
              >
            </div>
            <input
              class="form-control"
              formControlName="data"
              #drp="bsDaterangepicker"
              bsDaterangepicker
              placeholder="DD/MM/YYYY  - DD/MM/YYYY"
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >2ª Praça</span
              >
            </div>
            <input
              class="form-control"
              formControlName="data2"
              #drp="bsDaterangepicker"
              bsDaterangepicker
              placeholder="DD/MM/YYYY  - DD/MM/YYYY"
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
            />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Titulo</span
              >
            </div>
            <input type="text" class="form-control" formControlName="titulo" />
          </div>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="estadoId">Estado</label>
            </div>
            <ng-select
              style="flex: 1"
              formControlName="estado"
              (change)="onChangeEstado()"
              [items]="estados"
              id="estadoId"
              bindValue="estadoId"
              bindLabel="nome"
            ></ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="municipioId"
                >Municipio</label
              >
            </div>
            <ng-select
              style="flex: 1"
              formControlName="municipio"
              (change)="onChangeMunicipio()"
              [items]="municipios"
              id="municipioId"
              bindValue="municipioId"
              bindLabel="nome"
            ></ng-select>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <div class="filter" [formGroup]="filtro" *ngIf="filtro">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Bairro</span
                >
              </div>
              <ng-select
                style="flex: 1"
                [multiple]="true"
                formControlName="bairro"
                [items]="bairros"
                id="bairroId"
                bindValue="bairroId"
                bindLabel="nome"
              >
                <ng-template
                  ng-multi-label-tmp
                  let-items="items"
                  let-clear="clear"
                >
                  <div
                    class="ng-value"
                    *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                  >
                    <span class="ng-value-label"> {{ item.nome }}</span>
                    <span
                      class="ng-value-icon right"
                      (click)="clear(item)"
                      aria-hidden="true"
                      >×</span
                    >
                  </div>
                  <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label"
                      >mais {{ items.length - 1 }}...</span
                    >
                  </div>
                </ng-template></ng-select
              >
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Zona</span
              >
            </div>
            <input type="text" class="form-control" formControlName="zona" />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Origem</span
              >
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              placeholder="Origem"
              formControlName="origem"
              [items]="origens"
              id="origemId"
              bindValue="value"
              bindLabel="value"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.value }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label"
                    >mais {{ items.length - 1 }}...</span
                  >
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Referência</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              formControlName="referencia"
            />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="input-group col-12">
          <div class="input-group-prepend">
            <label class="input-group-text" for="faixa">Faixa de Valor</label>
          </div>
          <input
            type="text"
            class="form-control"
            formControlName="valorInicial"
            placeholder="Valor Inicial"
            id="faixa"
            currencyMask
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ','
            }"
          />
          <input
            type="text"
            class="form-control"
            formControlName="valorFinal"
            placeholder="Valor Final"
            id="faixa"
            currencyMask
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ','
            }"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Tipo Imóvel</span
              >
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              placeholder="Tipo de Imóvel"
              formControlName="tipo"
              [items]="tipoImovel"
              id="tipoId"
              bindValue="tipoId"
              bindLabel="descricao"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.descricao }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label"
                    >mais {{ items.length - 1 }}...</span
                  >
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Tipo Pregão</span
              >
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              placeholder="Pregão"
              formControlName="pregao"
              [items]="tipoPregao"
              id="pregaoId"
              bindValue="pregaoId"
              bindLabel="descricao"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.descricao }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label"
                    >mais {{ items.length - 1 }}...</span
                  >
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-8">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Modalidade</span
              >
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              placeholder="Modalidade"
              formControlName="modalidade"
              [items]="modalidade"
              id="modalidadeId"
              bindValue="modalidadeId"
              bindLabel="descricao"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.descricao }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label"
                    >mais {{ items.length - 1 }}...</span
                  >
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Ativo?</span
              >
            </div>
            <select
              style="flex: 1"
              class="form-control"
              formControlName="imoveisActive"
            >
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Nº Processo</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              formControlName="numProcesso"
              [textMask]="{ mask: mask }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button
      class="btn btn-success float-right"
      (click)="onChangeFilter()"
      (click)="modalRef?.hide()"
    >
      Filtrar
    </button>
    <div *ngIf="filtrosAplicados > 0">
      <button
        class="btn btn-link float-right mr-2"
        (click)="removerFiltros()"
        (click)="modalRef?.hide()"
      >
        Remover filtros
      </button>
    </div>
  </div>
</ng-template>
