<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <p class="d-inline">
            <i class="fas fa-home"></i>
            &nbsp;Imoveis
          </p>
          <button
            class="btn btn-primary float-right"
            tooltip="Gerar Relatório - PDF"
            (click)="downloadRelatorio()"
            *ngIf="!exportandoPdf"
          >
            <i class="fas fa-file-pdf"></i> Gerar Relatório
          </button>
          <button class="btn btn-info float-right" *ngIf="exportandoPdf">
            <i class="fas fa-spin fa-spinner"></i> Gerando...
          </button>
        </div>
        <div class="card-block">
          <div class="text-muted mb-4" *ngIf="imoveis">
            Encontrados {{ imoveis.length }} Imóveis
            <button
              style="position: relative"
              class="btn btn-primary float-right"
              (click)="openModal(abrirFiltros)"
            >
              <i class="fas fa-filter"></i> Filtros
              <span
                *ngIf="filtrosAplicados > 0"
                class="badge badge-pill badge-danger numFiltros"
                >{{ filtrosAplicados }}</span
              >
            </button>
          </div>
          <div class="table-responsive mt-1">
            <table
              class="table table-striped"
              [mfData]="imoveis"
              #mfTable="mfDataTable"
              [mfRowsOnPage]="10"
            >
              <thead>
                <tr>
                  <th class="text-center" width="5%">
                    <mfDefaultSorter>Referência</mfDefaultSorter>
                  </th>
                  <th class="text-center" width="10%">
                    <mfDefaultSorter>Cidade</mfDefaultSorter>
                  </th>
                  <th class="text-center" width="10%">
                    <mfDefaultSorter>Bairro</mfDefaultSorter>
                  </th>
                  <th class="text-center" width="15%">
                    <mfDefaultSorter>Endereço</mfDefaultSorter>
                  </th>
                  <th class="text-center" width="15%">
                    <mfDefaultSorter>Descricao</mfDefaultSorter>
                  </th>
                  <th class="text-center" width="5%">
                    <mfDefaultSorter>Categoria</mfDefaultSorter>
                  </th>
                  <th class="text-center" width="15%">
                    <mfDefaultSorter>1° Leilão</mfDefaultSorter>
                  </th>
                  <th class="text-center" width="15%">
                    <mfDefaultSorter>2° Leilão</mfDefaultSorter>
                  </th>
                  <th class="text-center" width="5%">
                    <mfDefaultSorter>Modalidade</mfDefaultSorter>
                  </th>
                  <th class="text-center" width="5%">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of mfTable.data">
                  <td class="text-center">{{ item.id }}</td>
                  <td class="text-center">{{ item.endereco.municipio }}</td>
                  <td class="text-center">{{ item.endereco.bairro }}</td>
                  <td class="text-center">{{ item.endereco.completo }}</td>
                  <td class="text-center">
                    <p
                      class="texto-descricao"
                      title="{{ item.descricaoDetalhada }}"
                    >
                      {{ item.descricaoDetalhada }}
                    </p>
                  </td>
                  <td class="text-center">{{ item.tipo }}</td>
                  <td class="text-center">
                    {{ item.praca1.valor }}
                    <br />
                    {{ item.praca1.data }}
                  </td>
                  <td class="text-center" *ngIf="item.praca2">
                    {{ item.praca2.valor }}
                    <br />
                    {{ item.praca2.data }}
                  </td>
                  <td class="text-center" *ngIf="!item.praca2"></td>
                  <td class="text-center">
                    {{ item.modalidade }}
                  </td>
                  <td class="text-center" nowrap>
                    <a
                      (click)="openModalEdit(editarItem, item)"
                      tooltip="Editar"
                      class="mr-3 pointer"
                      ><i class="fa-lg far fa-edit"></i
                    ></a>
                    <a
                      (click)="removerDaLista(item.id)"
                      tooltip="Excluir"
                      class="mr-1 pointer"
                      ><i class="fa-lg far fa-times-circle text-danger"></i
                    ></a>
                  </td>
                </tr>
                <tr *ngIf="loading">
                  <td class="text-center text-muted" colspan="12">
                    <h5>
                      <i class="fa fa-spin fa-spinner"></i>&nbsp;Carregando...
                    </h5>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="12">
                    <mfBootstrapPaginator
                      [rowsOnPageSet]="[5, 10, 25]"
                    ></mfBootstrapPaginator>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #abrirFiltros>
  <div class="modal-header bg-primary">
    <h5 class="modal-title" id="filtrosModalLabel">
      <i class="fas fa-filter"></i> Filtros
    </h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="container-fluid" [formGroup]="filtro">
    <div class="modal-body filtro">
      <div class="row mb-2">
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >1ª Praça</span
              >
            </div>
            <input
              class="form-control"
              #drp="bsDaterangepicker"
              bsDaterangepicker
              placeholder="DD/MM/YYYY  - DD/MM/YYYY"
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
              formControlName="data"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >2ª Praça</span
              >
            </div>
            <input
              class="form-control"
              #drp="bsDaterangepicker"
              bsDaterangepicker
              placeholder="DD/MM/YYYY  - DD/MM/YYYY"
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
              formControlName="data2"
            />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Titulo</span
              >
            </div>
            <input type="text" class="form-control" formControlName="titulo" />
          </div>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="estadoId">Estado</label>
            </div>
            <ng-select
              style="flex: 1"
              formControlName="estado"
              (change)="onChangeEstado()"
              [items]="estados"
              id="estadoId"
              bindValue="estadoId"
              bindLabel="nome"
            ></ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="municipioId"
                >Municipio</label
              >
            </div>
            <ng-select
              style="flex: 1"
              formControlName="municipio"
              (change)="onChangeMunicipio()"
              [multiple]="true"
              [items]="municipios"
              id="municipioId"
              bindValue="municipioId"
              bindLabel="nome"
              ><ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div class="ng-value" *ngIf="items.length > 0">
                  <span class="ng-value-label"
                    >{{ items.length }} Municipio{{
                      items.length > 1 ? "s" : ""
                    }}...</span
                  >
                </div>
              </ng-template></ng-select
            >
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <div class="filter" [formGroup]="filtro" *ngIf="filtro">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Bairro</span
                >
              </div>
              <ng-select
                style="flex: 1"
                [multiple]="true"
                formControlName="bairro"
                [items]="bairros"
                id="bairroId"
                bindValue="bairroId"
                bindLabel="nome"
                [groupBy]="groupByMunicipio"
                [groupValue]="groupValueFn"
              >
                <ng-template ng-optgroup-tmp let-item="item">
                  <b>{{ item.name }}</b>
                </ng-template>
                <ng-template
                  ng-multi-label-tmp
                  let-items="items"
                  let-clear="clear"
                >
                  <div
                    class="ng-value"
                    *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                  >
                    <span class="ng-value-label"> {{ item.nome }}</span>
                    <span
                      class="ng-value-icon right"
                      (click)="clear(item)"
                      aria-hidden="true"
                      >×</span
                    >
                  </div>
                  <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label"
                      >mais {{ items.length - 1 }}...</span
                    >
                  </div>
                </ng-template></ng-select
              >
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Zona</span
              >
            </div>
            <input type="text" class="form-control" formControlName="zona" />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Origem</span
              >
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              placeholder="Origem"
              formControlName="origem"
              [items]="origem"
              id="origemId"
              bindValue="origemId"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label"
                    >mais {{ items.length - 1 }}...</span
                  >
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Referência</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              formControlName="referencia"
            />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="input-group col-12">
          <div class="input-group-prepend">
            <label class="input-group-text" for="faixa">Faixa de Valor</label>
          </div>
          <input
            type="text"
            class="form-control"
            formControlName="valorInicial"
            placeholder="Valor Inicial"
            id="faixa"
            currencyMask
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ','
            }"
          />
          <input
            type="text"
            class="form-control"
            formControlName="valorFinal"
            placeholder="Valor Final"
            id="faixa"
            currencyMask
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ','
            }"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Tipo Imóvel</span
              >
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              placeholder="Tipo de Imóvel"
              formControlName="tipo"
              [items]="tipoImovel"
              id="tipoId"
              bindValue="tipoId"
              bindLabel="descricao"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.descricao }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label"
                    >mais {{ items.length - 1 }}...</span
                  >
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Tipo Pregão</span
              >
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              placeholder="Pregão"
              formControlName="pregao"
              [items]="tipoPregao"
              id="pregaoId"
              bindValue="pregaoId"
              bindLabel="descricao"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.descricao }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label"
                    >mais {{ items.length - 1 }}...</span
                  >
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-8">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Modalidade</span
              >
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              placeholder="Modalidade"
              formControlName="modalidade"
              [items]="modalidade"
              id="modalidadeId"
              bindValue="modalidadeId"
              bindLabel="descricao"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 1; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.descricao }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label"
                    >mais {{ items.length - 1 }}...</span
                  >
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Ativo?</span
              >
            </div>
            <select
              style="flex: 1"
              class="form-control"
              formControlName="imoveisActive"
            >
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Nº Processo</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              formControlName="numProcesso"
              [textMask]="{ mask: mask }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button
      class="btn btn-success float-right"
      (click)="onChangeFilter()"
      (click)="modalRef?.hide()"
    >
      Filtrar
    </button>
    <div *ngIf="filtrosAplicados > 0">
      <button
        class="btn btn-link float-right mr-2"
        (click)="removerFiltros()"
        (click)="modalRef?.hide()"
      >
        Remover filtros
      </button>
    </div>
  </div>
</ng-template>
<ng-template #editarItem>
  <div class="modal-header bg-primary">
    <h5 class="modal-title" id="filtrosModalLabel">
      <i class="fa-lg far fa-edit"></i> Editar Imovel
    </h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="container-fluid">
    <div class="modal-body">
      <div class="row mb-1">
        <div class="col-6">
          <label for="cidade">Cidade</label>
          <input
            id="cidade"
            type="text"
            class="form-control"
            [(ngModel)]="imovelEditando.endereco.municipio"
          />
        </div>
        <div class="col-6">
          <label for="bairro">Bairro</label>
          <input
            id="bairro"
            type="text"
            class="form-control"
            [(ngModel)]="imovelEditando.endereco.bairro"
          />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          <label for="endereco">Endereço</label>
          <input
            id="endereco"
            type="text"
            class="form-control"
            [(ngModel)]="imovelEditando.endereco.completo"
          />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          <label for="categoria">Categoria</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="imovelEditando.tipo"
          />
        </div>
        <div class="col-6">
          <label for="modalidade">Modalidade</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="imovelEditando.modalidade"
          />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          <label for="primeiro">Valor 1° Leilão</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="imovelEditando.praca1.valor"
          />
        </div>
        <div class="col-6">
          <label for="primeiro">Data 1° Leilão</label>
          <input
            class="form-control"
            [(ngModel)]="imovelEditando.praca1.data"
            dateMask
          />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          <label for="segundo">Valor 2° Leilão</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="imovelEditando.praca2.valor"
          />
        </div>
        <div class="col-6">
          <label for="segundo">Data 2° Leilão</label>
          <input
            class="form-control"
            [(ngModel)]="imovelEditando.praca2.data"
            dateMask
            placeholder="DD/MM/YYYY"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="descricao">Descrição</label>
          <textarea
            rows="5"
            class="form-control"
            id="descricao"
            [(ngModel)]="imovelEditando.descricaoDetalhada"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button
      type="submit"
      class="btn btn-success float-right mr-1"
      (click)="salvarEdicao(imovelEditando)"
    >
      Salvar
    </button>
  </div>
</ng-template>
