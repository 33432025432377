import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Restangular } from 'ngx-restangular';
import { Response, TipoPessoa } from 'app/views/_models';

@Component({
  selector: 'app-update-checklist-campo',
  templateUrl: './update-checklist-campo.component.html',
  styleUrls: ['./update-checklist-campo.component.scss']
})
export class UpdateChecklistCampoComponent implements OnInit {

  formulario: FormGroup;
  tipos: any[] = [];
  id: number;

  constructor(private restAngular: Restangular, private notifier: NotifierService, private router: Router, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute) 
  { 
  }

  ngOnInit() {

    this.restAngular.one('checkListCampo/tipos').get().subscribe((res) => {
      this.tipos = res;
    }, (err) => {
      this.notifier.notify('error', 'erro ao obter tipos de campos');
    });

    this.id = this.activatedRoute.snapshot.params.id;

    this.restAngular.one('checklistCampo', this.id).get().subscribe(
      (data : Response<any>) => {
        const checklistCampo = data.data;

        this.formulario = this.formBuilder.group({
          checklistCampoId: [this.id],
          ordem: [checklistCampo.ordem, Validators.required],
          label: [checklistCampo.label, Validators.required],
          tipo: [checklistCampo.tipo, Validators.required],
          campoPdf: [checklistCampo.campoPdf]
        });
      }
    );
  }

  onSubimit(){
    this.restAngular.all('checklistCampo').customPUT(this.formulario.value).subscribe(
      () => {
        this.notifier.notify('success', 'ChecklistCampo atualizado com sucesso');
        this.router.navigate(['/checklist-campos']);
      },
      () => {
        this.notifier.notify('error', 'Erro ao atualizar checklistCampo');
      }
    );
  }

  verificaValidTouched(campo) {
    return !this.formulario.get(campo).valid && this.formulario.get(campo).touched;
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) }
  }

}
