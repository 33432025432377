import {
  Component,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  HostListener,
  OnDestroy,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmailEditorComponent } from 'angular-email-editor';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss'],
})
export class CreateTemplateComponent implements OnInit, OnDestroy {
  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;
  title = 'angular-email-editor';
  salvar = false;
  template;
  formulario;
  options = {
    locale: 'pt-BR',
    displayMode: 'email',
    features: {
      textEditor: {
        tables: true,
      },
    },
  };
  cardImageBase64;
  isImageSaved;
  openPopup = true;
  modalRef: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private router: Router,
    private modalService: BsModalService,
    private restangular: Restangular
  ) {
    this.formulario = this.formBuilder.group({
      descricao: [null, Validators.required],
      tag: [''],
    });
  }

  ngOnInit() {}

  editorReady(event) {
    const that = this;
    this.emailEditor.editor.addEventListener('design:updated', () => {
      this.salvar = true;
      this.emailEditor.editor.exportHtml((data) => {
        this.template = {
          designJson: JSON.stringify(data.design),
          codigoHtml: data.html,
          descricao: this.formulario.value.descricao,
        };
        this.salvar = false;
      });
    });

    this.emailEditor.editor.setMergeTags({
      nome_cliente: {
        name: 'Nome do Cliente',
        value: '{{nome_cliente}}',
      },
      documento_cliente: {
        name: 'Documento do Cliente',
        value: '{{documento_cliente}}',
      },
      celular_cliente: {
        name: 'Celular do Cliente',
        value: '{{celular_cliente}}',
      },
      email_cliente: {
        name: 'Email do Cliente',
        value: '{{email_cliente}}',
      },
      qtd_imoveis: {
        name: 'Quantidade de Imoveis',
        value: '{{qtd_imoveis}}',
      },
      imoveis: {
        name: 'Imoveis',
        rules: {
          repeat: {
            name: 'Lista de Imoveis',
            before: '[imovel]',
            after: '[/imovel]',
          },
        },
        mergeTags: {
          titulo: {
            name: 'Titulo',
            value: '{{titulo}}',
          },
          endereco: {
            name: 'Endereço',
            value: '{{endereco}}',
          },
          dataPrimeiraPraca: {
            name: 'Data 1ª Praça',
            value: '{{data_primeira_praca}}',
          },
          valorPrimeiraPraca: {
            name: 'Valor 1ª Praça',
            value: '{{valor_segunda_praca}}',
          },
          dataSegundaPraca: {
            name: 'Data 2ª Praça',
            value: '{{data_segunda_praca}}',
          },
          valorSegundaPraca: {
            name: 'Valor 2ª Praça',
            value: '{{valor_segunda_praca}}',
          },
          foto: {
            name: 'Foto',
            value: '{{foto}}',
          },
        },
      },
    });

    this.emailEditor.editor.registerCallback('image', function (file, done) {
      const imagem: any = {
        base64: '',
        tipo: '',
        nome: '',
      };

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const imgBase64Path = e.target.result;
          that.cardImageBase64 = imgBase64Path;
          that.isImageSaved = true;
          imagem.base64 = imgBase64Path;
          imagem.nome = file.attachments[0].name;
          imagem.tipo = file.attachments[0].type;

          // that.restangular
          //   .all('marketing/imageTemplate')
          //   .post(imagem)
          //   .subscribe((a) => {
          //     done({ progress: 100, url: a.data.url });
          //   });
        };
      };
      reader.readAsDataURL(file.attachments[0]);
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  save() {
    this.salvar = true;
    console.log(this.formulario);
    if (!this.formulario.valid) {
      Object.keys(this.formulario.controls).forEach((campo) => {
        const controle = this.formulario.get(campo);
        controle.markAsTouched();
      });
      this.notifierService.notify(
        'error',
        'Preencha todos os campos obrigatórios'
      );
      this.salvar = false;
      return false;
    }

    if (!this.template) {
      this.notifierService.notify(
        'error',
        'Template vazio, adicione mais elementos'
      );
      this.salvar = false;
      return false;
    }

    this.template = {
      descricao: this.formulario.value.descricao,
      codigoHtml: this.template.codigoHtml,
      designJson: this.template.designJson,
      tag: this.formulario.value.tag,
    };

    this.restangular
      .all('template')
      .post(this.template)
      .subscribe(
        (a) => {
          this.notifierService.notify('success', 'Template criado com sucesso');
          this.salvar = false;
          this.router.navigate(['/template']);
        },
        (error) => {
          this.salvar = false;
          this.notifierService.notify('error', 'Erro ao criar o template!');
        }
      );

    this.modalRef.hide();
  }

  verificaValidTouched(campo) {
    this.formulario.controls[campo].valueChanges.subscribe((val) => {
      if (String(val) === 'NaN') {
        this.formulario.controls[campo].setValue(null);
      }
    });
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) };
  }
  ngOnDestroy(): void {}
}
