import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import { forkJoin } from 'rxjs';
import { Usuario } from 'app/views/_models';
@Component({
  selector: 'app-update-usuarios',
  templateUrl: './update-usuarios.component.html',
  styleUrls: ['./update-usuarios.component.scss'],
})
export class UpdateUsuariosComponent implements OnInit {
  formulario: FormGroup;
  usuarioId: number;

  perfis = [];
  fieldTextType: boolean;
  imageError: string;
  cardImageBase64: any;
  isImageSaved: boolean;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private restangular: Restangular,
    private notifierService: NotifierService,
    private routerLink: ActivatedRoute
  ) {
    this.formulario = this.formBuilder.group({
      nome: [null, [Validators.required, Validators.minLength(3)]],
      perfilId: [[], Validators.required],
      orgao: [''],
      matricula: [''],
      login: ['', Validators.required],
      senha: [null],
      assinatura: this.formBuilder.group({
        arquivoId: [0],
        nome: [''],
        base64: [''],
        tipo: [''],
        tamanho: [0],
      }),
    });
  }

  ngOnInit() {
    this.usuarioId = this.routerLink.snapshot.params['id'];

    forkJoin([
      this.restangular.one('/perfil').get().pipe(),
      this.restangular.one('/usuario', this.usuarioId).get().pipe(),
    ]).subscribe((allResp: any[]) => {
      this.perfis = allResp[0].data;
      this.updateForm(allResp[1].data);
    });
  }

  updateForm(usuario: Usuario) {
    this.formulario.patchValue({
      nome: usuario.nome,
      perfilId: usuario.perfilId,

      orgao: usuario.orgao || '',
      matricula: usuario.matricula || '',
      login: usuario.login || '',
      assinatura:
        usuario.assinatura != null
          ? {
              arquivoId: usuario.assinatura.arquivoId,
              nome: usuario.assinatura.nome,
              base64: usuario.assinatura.base64,
              tipo: usuario.assinatura.tipo,
              tamanho: usuario.assinatura.tamanho,
            }
          : {
              arquivoId: 0,
              nome: '',
              base64: '',
              tipo: '',
              tamanho: 0,
            },
    });

    this.isImageSaved = usuario.assinatura != null ? true : false;
    this.cardImageBase64 =
      usuario.assinatura != null ? usuario.assinatura.url : null;
  }

  onSubmit() {
    if (!this.formulario.valid) {
      Object.keys(this.formulario.controls).forEach((campo) => {
        const controle = this.formulario.get(campo);
        controle.markAsTouched();
      });
      this.notifierService.notify(
        'error',
        'Preencha todos os campos obrigatórios'
      );
      return;
    }
    this.restangular
      .all('usuario')
      .customPUT({ ...this.formulario.value, usuarioId: this.usuarioId })
      .subscribe(
        (a) => {
          this.notifierService.notify('success', 'Usuário criado com sucesso');
          this.router.navigateByUrl('/usuarios');
        },
        (error) => {
          const errors = error.data.Errors;
          for (const k in errors) {
            if (k.toLowerCase() === 'exception') {
              this.notifierService.notify('error', 'Erro ao atualizar usuário');
            } else {
              this.notifierService.notify('error', errors[k]);
            }
          }

          Object.keys(this.formulario.controls).forEach((campo) => {
            const controle = this.formulario.get(campo);
            controle.markAsTouched();
          });
        }
      );
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return {
      'has-error': this.verificaValidTouched(campo),
    };
  }

  onValueChange(event, campo) {
    this.formulario.get(campo).markAsTouched();
    this.formulario.get(campo).setValue(event);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 5242880;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'O tamanho máximo permitido é 5Mb';

        return false;
      }

      if (!allowed_types.includes(fileInput.target.files[0].type)) {
        this.imageError = 'Somente imagens são permitidas ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Tamanho máximo permitido ' + max_height + '*' + max_width + 'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            let assinatura = this.formulario.get('assinatura') as FormGroup;
            assinatura.get('base64').setValue(imgBase64Path);
            assinatura.get('nome').setValue(fileInput.target.files[0].name);
            assinatura.get('tamanho').setValue(fileInput.target.files[0].size);
            assinatura.get('tipo').setValue(fileInput.target.files[0].type);
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeImage(input) {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    input.value = '';
  }
}
