import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Cliente, Endereco, Response, TipoPessoa } from 'app/views/_models';
import { ConsultaCepService } from 'app/views/usuarios/shared/consulta-cep/consulta-cep.service';
import { Restangular } from 'ngx-restangular';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-cliente',
  templateUrl: './edit-cliente.component.html',
  styleUrls: ['./edit-cliente.component.scss'],
})
export class EditClienteComponent implements OnInit {
  @ViewChild('inputDocumentos') inputDocumentos: ElementRef;
  formulario: FormGroup;
  clienteId: number;
  selectedFile?: File;
  fileName: string = 'Escolha o arquivo';
  tipodocumento: [];
  fileToUpload: File | null = null;

  //masks
  public mask: Array<string | RegExp>;
  public maskCep: Array<string | RegExp>;
  public maskCnpj: Array<string | RegExp>;
  public maskCpf: (string | RegExp)[];
  public maskRg: (string | RegExp)[];

  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];

  //Documentos
  documentosbase64: any;
  numeroAdcDocumento: number;

  tiposPessoa: TipoPessoa[] = [];
  bairrosAgrupados: { label: string; children: any }[];
  salvando = false;
  constructor(
    private formBuilder: FormBuilder,
    private restangular: Restangular,
    private notifierService: NotifierService,
    private router: Router,
    private cepService: ConsultaCepService,
    private route: ActivatedRoute
  ) {
    this.mask = [
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
    this.maskCep = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.maskCpf = [
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
    this.maskCnpj = [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
    this.maskRg = [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
    ];
  }

  hasLocalidadeInteresse = this.formBuilder.control(false);
  hasEndereco = this.formBuilder.control(false);

  ngOnInit() {
    this.clienteId = this.route.snapshot.params['id'];

    this.getTipoDocumento();

    this.restangular
      .one('cliente', this.clienteId)
      .get()
      .subscribe(
        (response: Response<Cliente>) => {
          this.updateForm(response.data);
        },
        (error: any) => {
          this.notifierService.notify(
            'error',
            'Não foi possível carregar o cliente!'
          );
          this.router.navigate(['/cliente']);
        }
      );

    this.restangular
      .one('cliente/tipoPessoa')
      .get()
      .subscribe((res: any) => {
        this.tiposPessoa = res.data;
      });

    this.restangular
      .one('localidade/estado')
      .get()
      .subscribe((estados) => {
        this.estados = estados.data;
      });
  }

  updateForm(data: Cliente) {
    this.formulario = this.formBuilder.group({
      clienteId: [data.clienteId],
      nome: [data.nome, Validators.required],
      rg: [data.rg],
      nomeContato: [data.nomeContato],
      email: [data.email],
      telefone: [data.telefone],
      celular: [data.celular],
      tipoPessoaId: [data.tipoPessoaId, Validators.required],
      valorInicial: [data.valorInicial],
      valorFinal: [data.valorFinal],
      cpfCnpj: [data.cpfCnpj, Validators.required],
      nacionalidade: [data.nacionalidade],
      estadoCivil: [data.estadoCivil],
      profissao: [data.profissao],
      bairroId: [null],
      municipioId: [null],
      estadoId: [null],
      documentos: this.formBuilder.array(
        data.documentos
          ? data.documentos.map((x) =>
              this.formBuilder.group({
                tipoDocumentoId: x.tipoDocumentoId,
                arquivoId: x.arquivoId,
                arquivo: x.arquivo,
                acao: 'A',
              })
            )
          : []
      ),
      localidadesInteresse: data.localidadesInteresse
        ? this.formBuilder.array(
            data.localidadesInteresse.map((x) =>
              this.formBuilder.group({
                bairroId: [x.bairroId],
                bairro: [x.bairro.nome],
                municipio: [x.bairro.municipio.nome],
                estado: [x.bairro.municipio.estado.nome],
              })
            )
          )
        : this.formBuilder.array([]),
      observacao: [data.observacao],
    });

    this.formulario.get('municipioId').disable();
    this.formulario.get('bairroId').disable();

    if (data.localidadesInteresse) this.hasLocalidadeInteresse.setValue(true);

    if (data.endereco) {
      this.hasEndereco.setValue(true);
      this.formulario.addControl(
        'endereco',
        this.formBuilder.group({
          cep: [data.endereco.cep],
          logradouro: [data.endereco.logradouro],
          complemento: [data.endereco.complemento],
          bairro: [data.endereco.bairro],
          cidade: [data.endereco.cidade],
          estado: [data.endereco.estado],
          numero: [data.endereco.numero],
        })
      );
    }
  }

  addEndereco() {
    if (this.hasEndereco && !this.formulario.get('endereco')) {
      this.formulario.addControl(
        'endereco',
        this.formBuilder.group({
          enderecoId: [0],
          cep: [''],
          numero: [''],
          complemento: [''],
          bairro: [''],
          cidade: [''],
          estado: [''],
          logradouro: [''],
        })
      );
    } else if (!this.hasEndereco) {
      this.formulario.removeControl('endereco');
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.fileName = this.selectedFile.name;
    } else {
      this.fileName = 'Escolha o arquivo';
    }
  }

  getTipoDocumento() {
    this.restangular
      .one('tipodocumento')
      .get()
      .subscribe(
        (response) => {
          this.tipodocumento = response.data;
        },
        (error) => {
          this.notifierService.notify(
            'error',
            'Não foi possível carregar os tipos de documentos'
          );
        }
      );
  }

  onChangeEstado() {
    this.formulario.get('municipioId').disable();

    if (this.formulario.value.estadoId === null) {
      this.formulario.get('municipioId').disable();
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/municipio')
      .get({ estadoId: this.formulario.value.estadoId })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.formulario.get('municipioId').enable();
      });
  }

  onChangeMunicipio() {
    const municipioId = this.formulario.value.municipioId;

    if (municipioId === null) {
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/bairro')
      .get({ municipioId })
      .subscribe((res) => {
        const bairros = res.data;

        // Organizar os bairros por zona usando reduce
        const bairrosPorZona = bairros.reduce((acc, bairro) => {
          const zona = bairro.zona || 'Sem Zona';

          if (!acc[zona]) {
            acc[zona] = [];
          }
          acc[zona].push({ bairroId: bairro.bairroId, nome: bairro.nome });
          return acc;
        }, {});

        // Converter para o formato desejado
        const bairrosFormatados = Object.keys(bairrosPorZona).map((zona) => {
          return {
            label: zona,
            children: bairrosPorZona[zona],
          };
        });

        // Atualizar os bairros organizados por zona
        this.bairrosAgrupados = bairrosFormatados;
        this.bairros = bairros;
        this.formulario.get('bairroId').enable();
      });
  }

  documentoChangeEvent(documentoInput: FileList) {
    this.fileToUpload = documentoInput.item(0);
    this.fileToUpload.name;
    this.fileToUpload.size;
    this.fileToUpload.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      this.documentosbase64 = reader.result;
      const arquivo = this.formBuilder.group({
        arquivoId: null,
        nome: this.fileToUpload.name,
        tipo: this.fileToUpload.type,
        tamanho: this.fileToUpload.size,
        base64: this.documentosbase64,
      });

      this.atualizarDocumento(arquivo, this.numeroAdcDocumento);
    };
  }

  atualizarDocumento(obj, i) {
    const documentos = this.formulario.get('documentos') as FormArray;

    if (i < 0) {
      documentos.push(
        this.formBuilder.group({
          arquivo: obj,
          acao: 'I',
          tipoDocumentoId: [null, Validators.required],
        })
      );
    } else {
      const valor = documentos.value[i];
      documentos.removeAt(i);
      documentos.insert(
        i,
        this.formBuilder.group({
          arquivo: obj,
          acao: 'A',
          tipoDocumentoId: [valor.tipoDocumentoId, Validators.required],
        })
      );
    }
  }

  alterarDocumento(i) {
    this.numeroAdcDocumento = i;
    this.inputDocumentos.nativeElement.click();
  }

  deleteDocumento(indexDocumento: number) {
    const documentos = this.formulario.controls['documentos'] as FormArray;
    const documento = documentos.at(indexDocumento) as FormGroup;
    if (documento.controls['acao'].value !== 'I') {
      documento.controls['acao'].setValue('D');
    } else {
      documentos.removeAt(indexDocumento);
    }
  }

  filterList(campo: string) {
    const fotos = this.formulario.get(campo) as FormArray;
    return fotos.controls.filter(
      (x) => (x as FormGroup).controls['acao'].value !== 'D'
    );
  }

  addLocalidadeInteresse() {
    const localidadesInteresse = this.formulario.get(
      'localidadesInteresse'
    ) as FormArray;

    if (
      localidadesInteresse.controls.filter(
        (x) => x.value.bairroId === this.formulario.value.bairroId
      ).length > 0
    ) {
      this.notifierService.notify('error', 'Localidade já adicionada');
      return;
    }

    localidadesInteresse.push(
      this.formBuilder.group({
        bairroId: [this.formulario.value.bairroId],
        bairro: [
          this.bairros.find(
            (bairro) => bairro.bairroId === this.formulario.value.bairroId
          ).nome,
        ],
        municipio: [
          this.municipios.find(
            (municipio) =>
              municipio.municipioId === this.formulario.value.municipioId
          ).nome,
        ],
        estado: [
          this.estados.find(
            (estado) => estado.estadoId === this.formulario.value.estadoId
          ).nome,
        ],
      })
    );

    this.formulario.get('bairroId').reset();
  }

  removeLocalidadeInteresse(index) {
    const localidadesInteresse = this.formulario.get(
      'localidadesInteresse'
    ) as FormArray;
    localidadesInteresse.removeAt(index);
  }

  onSubmit() {
    if (!this.formulario.valid) {
      if (!this.formulario.get('documentos').valid) {
        this.notifierService.notify(
          'error',
          'Preencha o campo tipo do documento'
        );
        return false;
      }

      Object.keys(this.formulario.controls).forEach((campo) => {
        const controle = this.formulario.get(campo);
        controle.markAsTouched();
      });
      this.notifierService.notify(
        'error',
        'Preencha todos os campos obrigatórios'
      );
      return false;
    }

    this.salvando = true;

    const body = this.formulario.getRawValue();

    body.estadoCivil = Number(body.estadoCivil);
    if (isNaN(body.valorInicial)) body.valorInicial = 0;
    if (isNaN(body.valorFinal)) body.valorFinal = 0;

    body.localidadesInteresse = body.localidadesInteresse.map(
      (x) => x.bairroId
    );

    this.restangular
      .all('cliente')
      .customPUT(body)
      .subscribe(
        () => {
          this.salvando = false;
          this.notifierService.notify(
            'success',
            'Cliente atualizado com sucesso!'
          );
          this.router.navigate(['/clientes']);
        },
        () => {
          this.salvando = false;
          this.notifierService.notify(
            'error',
            'Não foi possível atualizar o cliente!'
          );
        }
      );
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) };
  }

  consultaCEP() {
    const cep = this.formulario.get('endereco.cep').value;

    if (cep != null && cep !== '') {
      this.cepService
        .consultaCEP(cep)
        .subscribe((dados) => this.populaDadosForm(dados));
    }
  }

  populaDadosForm(dados: any) {
    this.formulario.patchValue({
      endereco: {
        logradouro: dados.logradouro,
        complemento: dados.complemento,
        bairro: dados.bairro,
        cidade: dados.localidade,
        estado: dados.uf,
      },
    });
  }
}
