import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import { EmailEditorComponent } from 'angular-email-editor';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-update-template',
  templateUrl: './update-template.component.html',
  styleUrls: ['./update-template.component.scss'],
})
export class UpdateTemplateComponent implements OnInit {
  title = 'angular-email-editor';
  templateExist = false;
  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;
  id;
  salvar = false;
  // template: { codigoHtml: string; descricao: string };
  template: any;
  formulario: any;
  designJson;
  options = {
    locale: 'pt-BR',
    displayMode: 'email',
    features: {
      textEditor: {
        tables: true,
      },
    },
  };
  cardImageBase64;
  isImageSaved;
  openPopup = true;
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private router: Router,
    private restangular: Restangular,
    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.params['id'];

    this.formulario = this.formBuilder.group({
      descricao: [null, Validators.required],
      tag: [''],
    });
  }

  ngOnInit() {
    this.restangular
      .all('template')
      .get(this.id)
      .subscribe((dados) => {
        this.designJson = JSON.parse(dados.data.designJson);
        this.formulario = this.formBuilder.group({
          descricao: [dados.data.descricao, Validators.required],
          codigoHtml: [dados.data.codigoHtml, Validators.required],
          designJson: [dados.data.designJson],
          tag: [dados.data.tag],
        });
      });
  }

  editorReady(event) {
    const that = this;
    this.setDesign();
    this.exportHtml();

    this.emailEditor.editor.addEventListener('design:updated', (updates) => {
      this.exportHtml();
    });

    this.emailEditor.editor.setMergeTags({
      nome_cliente: {
        name: 'Nome do Cliente',
        value: '{{nome_cliente}}',
      },
      documento_cliente: {
        name: 'Documento do Cliente',
        value: '{{documento_cliente}}',
      },
      celular_cliente: {
        name: 'Celular do Cliente',
        value: '{{celular_cliente}}',
      },
      email_cliente: {
        name: 'Email do Cliente',
        value: '{{email_cliente}}',
      },
      qtd_imoveis: {
        name: 'Quantidade de Imoveis',
        value: '{{qtd_imoveis}}',
      },
      imoveis: {
        name: 'Imoveis',
        rules: {
          repeat: {
            name: 'Lista de Imoveis',
            before: '[imoveis]',
            after: '[/imoveis]',
          },
        },
        mergeTags: {
          titulo: {
            name: 'Titulo',
            value: '{{titulo_imovel}}',
          },
          endereco: {
            name: 'Endereço',
            value: '{{endereco_imovel}}',
          },
          dataPrimeiraPraca: {
            name: 'Data 1ª Praça',
            value: '{{data_primeira_praca}}',
          },
          valorPrimeiraPraca: {
            name: 'Valor 1ª Praça',
            value: '{{valor_primeira_praca}}',
          },
          dataSegundaPraca: {
            name: 'Data 2ª Praça',
            value: '{{data_segunda_praca}}',
          },
          valorSegundaPraca: {
            name: 'Valor 2ª Praça',
            value: '{{valor_segunda_praca}}',
          },
          image: {
            name: 'Foto',
            value: '{{foto_imovel}}',
            sample:
              'https://k1planejamento.com.br/wp-content/uploads/2023/04/7.jpg',
          },
        },
      },
    });

    this.emailEditor.editor.registerCallback('image', function (file, done) {
      const imagem: any = {
        base64: '',
        tipo: '',
        nome: '',
      };

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const imgBase64Path = e.target.result;
          that.cardImageBase64 = imgBase64Path;
          that.isImageSaved = true;
          imagem.base64 = imgBase64Path;
          imagem.nome = file.attachments[0].name;
          imagem.tipo = file.attachments[0].type;

          that.restangular
            .all('marketing/imageTemplate')
            .post(imagem)
            .subscribe((a) => {
              done({ progress: 100, url: a.data.url });
            });
        };
      };
      reader.readAsDataURL(file.attachments[0]);
    });
  }

  exportHtml() {
    this.salvar = true;
    this.emailEditor.editor.exportHtml((data) => {
      this.template = {
        designJson: JSON.stringify(data.design),
        codigoHtml: data.html,
        descricao: this.formulario.value.descricao,
      };
      this.salvar = false;
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  save() {
    this.salvar = true;

    if (!this.formulario.valid) {
      Object.keys(this.formulario.controls).forEach((campo) => {
        const controle = this.formulario.get(campo);
        controle.markAsTouched();
      });
      this.notifierService.notify(
        'error',
        'Preencha todos os campos obrigatórios'
      );
      this.salvar = false;
      return false;
    }

    this.template = {
      designJson:
        this.template && this.template.designJson
          ? this.template.designJson
          : this.formulario.value.designJson,
      codigoHtml:
        this.template && this.template.codigoHtml
          ? this.template.codigoHtml
          : this.formulario.value.codigoHtml,
      descricao: this.formulario.value.descricao,
      templateNotificacaoId: this.id,
      tag: this.formulario.value.tag,
    };

    this.restangular
      .all('template')
      .customPUT(this.template, this.id)
      .subscribe(
        (a) => {
          this.salvar = false;
          this.notifierService.notify(
            'success',
            'Template atualizado com sucesso'
          );
          this.router.navigate(['template']);
        },
        (error) => {
          this.salvar = false;
          this.notifierService.notify('error', 'Erro ao atualizar o template!');
        }
      );

    this.modalRef.hide();
  }

  setDesign() {
    this.emailEditor.editor.loadDesign(this.designJson);
  }

  verificaValidTouched(campo) {
    this.formulario.controls[campo].valueChanges.subscribe((val) => {
      if (String(val) === 'NaN') {
        this.formulario.controls[campo].setValue(null);
      }
    });
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) };
  }
}
