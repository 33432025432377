var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ConsultaCepService } from 'app/views/usuarios/shared/consulta-cep/consulta-cep.service';
import { Restangular } from 'ngx-restangular';
var UpdateContatoComponent = /** @class */ (function () {
    function UpdateContatoComponent(formBuilder, restangular, notifierService, router, cepService, route) {
        this.formBuilder = formBuilder;
        this.restangular = restangular;
        this.notifierService = notifierService;
        this.router = router;
        this.cepService = cepService;
        this.route = route;
        this.loadorigem = false;
        this.origemcontato = [];
        //localidade
        this.estados = [];
        this.municipios = [];
        this.bairros = [];
        this.hasLocalidadeInteresse = this.formBuilder.control(true);
        this.hasEndereco = this.formBuilder.control(false);
        this.maskTelefone = [
            '(',
            /[1-9]/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ];
        this.maskCep = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    }
    UpdateContatoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getOrigemContato();
        this.id = this.route.snapshot.params['id'];
        this.getContatoById(this.id);
        this.restangular
            .one('localidade/estado')
            .get()
            .subscribe(function (estados) {
            _this.estados = estados.data;
        });
    };
    UpdateContatoComponent.prototype.onSubimit = function () {
        var _this = this;
        if (this.formulario.invalid) {
            this.notifierService.notify('error', 'Formulário inválido');
            return;
        }
        var body = __assign({}, this.formulario.value, { localidadesInteresse: this.formulario.value.localidadesInteresse.map(function (x) { return x.bairroId; }), estadoId: '' });
        this.restangular
            .one('contato')
            .customPUT(body)
            .subscribe(function () {
            if (_this.formulario.value.localidadesInteresse.length > 0) {
                if (_this.contato.clientId) {
                    _this.notifierService.notify('error', 'Já existe cliente cadastrado com esse contato!');
                }
                else {
                    _this.criaCliente(_this.id).subscribe(function () {
                        _this.notifierService.notify('success', 'Contato atualizado com sucesso');
                        _this.router.navigate(['contato']);
                    }, function (err) {
                        return _this.notifierService.notify('error', 'Erro ao criar cliente');
                    });
                }
            }
            else {
                _this.notifierService.notify('success', 'Contato atualizado com sucesso');
                _this.router.navigate(['contato']);
            }
        }, function (error) {
            _this.notifierService.notify('error', 'Erro ao atualizar contato');
        });
    };
    UpdateContatoComponent.prototype.updateFormulario = function (data) {
        var _this = this;
        this.formulario = this.formBuilder.group({
            nome: [data.nome, Validators.required],
            email: [data.email],
            telefone: [data.telefone],
            mensagem: [data.mensagem],
            contatoId: [data.contatoId, Validators.required],
            origemId: [data.origemId],
            comoContactar: [data.comoContactar],
            valorInicial: [data.valorInicial],
            valorFinal: [data.valorFinal],
            bairroId: [null],
            municipioId: [null],
            estadoId: [null],
            localidadesInteresse: data.localidadesInteresse
                ? this.formBuilder.array(data.localidadesInteresse.map(function (x) {
                    return _this.formBuilder.group({
                        bairroId: [x.bairroId],
                        bairro: [x.bairro.nome],
                        municipio: [x.bairro.municipio.nome],
                        estado: [x.bairro.municipio.estado.nome],
                    });
                }))
                : this.formBuilder.array([]),
        });
        this.formulario.get('municipioId').disable();
        this.formulario.get('bairroId').disable();
        if (data.localidadesInteresse)
            this.hasLocalidadeInteresse.setValue(true);
        if (data.endereco) {
            this.hasEndereco.setValue(true);
            this.formulario.addControl('endereco', this.formBuilder.group({
                cep: [data.endereco.cep],
                logradouro: [data.endereco.logradouro],
                complemento: [data.endereco.complemento],
                bairro: [data.endereco.bairro],
                cidade: [data.endereco.cidade],
                estado: [data.endereco.estado],
                numero: [data.endereco.numero],
            }));
        }
    };
    UpdateContatoComponent.prototype.getContatoById = function (id) {
        var _this = this;
        this.restangular
            .one('contato', id)
            .get()
            .subscribe(function (contato) {
            _this.updateFormulario(contato.data);
            _this.contato = contato.data;
        }, function (error) {
            _this.notifierService.notify('error', 'Erro ao buscar contato');
        });
    };
    UpdateContatoComponent.prototype.onChangeEstado = function () {
        var _this = this;
        this.formulario.get('municipioId').disable();
        if (this.formulario.value.estadoId === null) {
            this.formulario.get('municipioId').disable();
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restangular
            .one('localidade/municipio')
            .get({ estadoId: this.formulario.value.estadoId })
            .subscribe(function (municipios) {
            _this.municipios = municipios.data;
            _this.formulario.get('municipioId').enable();
        });
    };
    UpdateContatoComponent.prototype.onChangeMunicipio = function () {
        var _this = this;
        var municipioId = this.formulario.value.municipioId;
        if (municipioId === null) {
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restangular
            .one('localidade/bairro')
            .get({ municipioId: municipioId })
            .subscribe(function (res) {
            var bairros = res.data;
            // Organizar os bairros por zona usando reduce
            var bairrosPorZona = bairros.reduce(function (acc, bairro) {
                var zona = bairro.zona || 'Sem Zona';
                if (!acc[zona]) {
                    acc[zona] = [];
                }
                acc[zona].push({ bairroId: bairro.bairroId, nome: bairro.nome });
                return acc;
            }, {});
            // Converter para o formato desejado
            var bairrosFormatados = Object.keys(bairrosPorZona).map(function (zona) {
                return {
                    label: zona,
                    children: bairrosPorZona[zona],
                };
            });
            // Atualizar os bairros organizados por zona
            _this.bairrosAgrupados = bairrosFormatados;
            _this.bairros = bairros;
            _this.formulario.get('bairroId').enable();
        });
    };
    UpdateContatoComponent.prototype.criaCliente = function (contatoId) {
        return this.restangular.one('contato', contatoId).one('promover').post();
    };
    // promover(contatoId: number) {
    //   this.criaCliente(contatoId).subscribe(
    //     (response) => {
    //       this.notifierService.notify('success', 'Cliente criado com sucesso');
    //     },
    //     (error) => {
    //       this.notifierService.notify(
    //         'error',
    //         'Não foi possível criar o cliente.'
    //       );
    //     }
    //   );
    // }
    UpdateContatoComponent.prototype.addLocalidadeInteresse = function () {
        var _this = this;
        var localidadesInteresse = this.formulario.get('localidadesInteresse');
        if (localidadesInteresse.controls.filter(function (x) { return x.value.bairroId === _this.formulario.value.bairroId; }).length > 0) {
            this.notifierService.notify('error', 'Localidade já adicionada');
            return;
        }
        localidadesInteresse.push(this.formBuilder.group({
            bairroId: [this.formulario.value.bairroId],
            bairro: [
                this.bairros.find(function (bairro) { return bairro.bairroId === _this.formulario.value.bairroId; }).nome,
            ],
            municipio: [
                this.municipios.find(function (municipio) {
                    return municipio.municipioId === _this.formulario.value.municipioId;
                }).nome,
            ],
            estado: [
                this.estados.find(function (estado) { return estado.estadoId === _this.formulario.value.estadoId; }).nome,
            ],
        }));
        this.formulario.get('bairroId').reset();
    };
    UpdateContatoComponent.prototype.removeLocalidadeInteresse = function (index) {
        var localidadesInteresse = this.formulario.get('localidadesInteresse');
        localidadesInteresse.removeAt(index);
    };
    UpdateContatoComponent.prototype.verificaValidTouched = function (campo) {
        return (!this.formulario.get(campo).valid && this.formulario.get(campo).touched);
    };
    UpdateContatoComponent.prototype.aplicaCssErro = function (campo) {
        return { 'has-error': this.verificaValidTouched(campo) };
    };
    UpdateContatoComponent.prototype.consultaCEP = function () {
        var _this = this;
        var cep = this.formulario.get('endereco.cep').value;
        if (cep != null && cep !== '') {
            this.cepService
                .consultaCEP(cep)
                .subscribe(function (dados) { return _this.populaDadosForm(dados); });
        }
    };
    UpdateContatoComponent.prototype.addEndereco = function () {
        if (this.hasEndereco) {
            this.formulario.addControl('endereco', this.formBuilder.group({
                enderecoId: [0],
                cep: [''],
                numero: [''],
                complemento: [''],
                bairro: [''],
                cidade: [''],
                estado: [''],
                logradouro: [''],
            }));
        }
        else {
            this.formulario.removeControl('endereco');
        }
    };
    UpdateContatoComponent.prototype.populaDadosForm = function (dados) {
        this.formulario.patchValue({
            endereco: {
                logradouro: dados.logradouro,
                complemento: dados.complemento,
                bairro: dados.bairro,
                cidade: dados.localidade,
                estado: dados.uf,
            },
        });
    };
    UpdateContatoComponent.prototype.getOrigemContato = function () {
        var _this = this;
        this.loadorigem = true;
        this.restangular
            .one('origemcontato')
            .get()
            .subscribe(function (response) {
            _this.origemcontato = response.data;
            _this.loadorigem = false;
        }, function (error) {
            _this.notifierService.notify('error', 'Não foi possível carregar as origens');
            _this.loadorigem = false;
        });
    };
    return UpdateContatoComponent;
}());
export { UpdateContatoComponent };
