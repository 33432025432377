import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Restangular } from 'ngx-restangular';
import { Cliente, Contrato, Corretor, TipoPessoa } from 'app/views/_models';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-contrato',
  templateUrl: './create-contrato.component.html',
  styleUrls: ['./create-contrato.component.scss'],
})
export class CreateContratoComponent implements OnInit {
  formulario: FormGroup;
  clientes: Cliente[];
  corretores: Corretor[];
  contratos: Contrato[];
  loadingSubmit: boolean;
  visualizando = false;

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private localeService: BsLocaleService
  ) {
    localeService.use('pt-br');
  }

  ngOnInit() {
    this.restAngular
      .one('cliente')
      .get()
      .subscribe((res) => {
        this.clientes = res.data;
      });

    this.restAngular
      .one('corretor')
      .get()
      .subscribe((res) => {
        this.corretores = res.data;
      });

    this.formulario = this.formBuilder.group({
      clienteId: [null, Validators.required],
      corretorId: [null],
      dataAssinatura: [null, Validators.required],
      ativo: [true, Validators.required],
      porcentagem: [5, Validators.required],
      tipoContrato: [1, Validators.required],
      tipoAssinatura: [2],
    });
  }

  onSubimit() {
    this.loadingSubmit = true;

    const body = this.formulario.getRawValue();

    body.tipoAssinatura = Number(body.tipoAssinatura);
    body.tipoContrato = Number(body.tipoContrato);
    body.ativo = String(body.ativo) === 'true';

    this.restAngular
      .all('contrato')
      .post(body)
      .subscribe(
        () => {
          this.loadingSubmit = false;
          this.notifier.notify('success', 'Contrato cadastrado com sucesso');
          this.router.navigate(['/contratos']);
        },
        (err) => {
          this.loadingSubmit = false;
          this.notifier.notify('error', err.data.Message);
        }
      );
  }

  goBack(): void {
    this.location.back();
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) };
  }

  visualizarContrato() {
    const clienteId = this.formulario.value.clienteId;
    const porcentagem = this.formulario.value.porcentagem;

    if (!clienteId || !porcentagem) {
      this.notifier.notify('error', 'cliente deve estar selecionado!');
      return;
    }
    this.visualizando = true;
    this.restAngular
      .one('contrato/gerarExemplo/', clienteId)
      .withHttpConfig({ responseType: 'blob' })
      .get({ porcentagem })
      .subscribe((fileBlob: Blob) => {
        const file = new Blob([fileBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.visualizando = false;
      });
  }
}
