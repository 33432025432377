import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Restangular } from 'ngx-restangular';
import { forkJoin } from 'rxjs';
var UpdateImovelComponent = /** @class */ (function () {
    function UpdateImovelComponent(restangular, router, formBuilder, notifierService, route, localeService) {
        this.restangular = restangular;
        this.router = router;
        this.formBuilder = formBuilder;
        this.notifierService = notifierService;
        this.route = route;
        this.localeService = localeService;
        this.loading = false;
        this.fileToUpload = null;
        this.hasLocalidade = this.formBuilder.control(false);
        this.arrayFotos = [];
        this.arrayAnexos = [];
        //localidade
        this.estados = [];
        this.municipios = [];
        this.bairros = [];
        this.editorConfig = {
            editable: true,
            spellcheck: true,
            height: 'auto',
            minHeight: '0',
            maxHeight: '300px',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: true,
            showToolbar: true,
            placeholder: 'Descrição detalhada...',
            defaultParagraphSeparator: '',
            defaultFontName: '',
            defaultFontSize: '',
            fonts: [
                { class: 'roboto', name: 'Roboto' },
                { class: 'arial', name: 'Arial' },
                { class: 'times-new-roman', name: 'Times New Roman' },
                { class: 'calibri', name: 'Calibri' },
                { class: 'comic-sans-ms', name: 'Comic Sans MS' },
            ],
            sanitize: true,
        };
        localeService.use('pt-br');
        this.id = this.route.snapshot.params.id;
    }
    UpdateImovelComponent.prototype.ngOnInit = function () {
        var _this = this;
        forkJoin([
            this.restangular.one('imovel/tipo').get(),
            this.restangular.one('imovel/pregao').get(),
            this.restangular.one('imovel/campo').get(),
            this.restangular.one('imovel').one(this.id).get(),
            this.restangular.one('modalidade').get(),
            this.restangular.one('instituicao').get(),
            this.restangular.one('localidade/estado').get(),
        ]).subscribe(function (_a) {
            var tipos = _a[0], pregaos = _a[1], campos = _a[2], imovel = _a[3], modalidades = _a[4], instituicoes = _a[5], estados = _a[6];
            _this.tipos = tipos.data;
            _this.pregaos = pregaos.data;
            _this.campos = campos.data;
            _this.modalidades = modalidades.data;
            _this.instituicoes = instituicoes.data;
            _this.estados = estados.data;
            _this.updateForm(imovel.data);
        });
    };
    UpdateImovelComponent.prototype.onChangeEstado = function () {
        var _this = this;
        if (this.formulario.value.estadoId === null) {
            this.formulario.get('municipioId').disable();
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restangular
            .one('localidade/municipio')
            .get({ estadoId: this.formulario.value.estadoId })
            .subscribe(function (municipios) {
            _this.municipios = municipios.data;
            _this.formulario.get('municipioId').enable();
        });
    };
    UpdateImovelComponent.prototype.onChangeMunicipio = function () {
        var _this = this;
        var municipioId = this.formulario.value.municipioId;
        if (municipioId === null) {
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restangular
            .one('localidade/bairro')
            .get({ municipioId: municipioId })
            .subscribe(function (bairros) {
            _this.bairros = bairros.data;
            _this.formulario.get('bairroId').enable();
        });
    };
    UpdateImovelComponent.prototype.updateForm = function (dados) {
        var _this = this;
        this.formulario = this.formBuilder.group({
            imovelId: [dados.imovelId, Validators.required],
            tipoId: [dados.tipoId, Validators.required],
            pracaId: [dados.pracaId],
            titulo: [dados.titulo.toUpperCase(), Validators.required],
            descricao: [dados.descricao],
            googleMaps: [dados.googleMaps],
            pregaoId: [dados.pregaoId, Validators.required],
            pracas: this.formBuilder.array(dados.pracas
                ? dados.pracas.map(function (x) {
                    return _this.formBuilder.group({
                        data: [
                            moment.utc(x.praca.data).local().toDate(),
                            Validators.required,
                        ],
                        pracaId: [x.pracaId, Validators.required],
                        numero: [x.praca.numero, Validators.required],
                        valor: [x.praca.valor, Validators.required],
                    });
                })
                : []),
            linkOrigem: [dados.linkOrigem],
            campos: this.formBuilder.array(dados.campos
                ? dados.campos.map(function (x) {
                    return _this.formBuilder.group({
                        campoId: x.campoId,
                        valor: x.valor,
                        acao: '',
                    });
                })
                : []),
            anexos: this.formBuilder.array(dados.anexos
                ? dados.anexos.map(function (x) {
                    return _this.formBuilder.group({
                        nome: x.nome,
                        arquivoId: x.arquivoId,
                        arquivo: x.arquivo,
                        acao: 'A',
                    });
                })
                : []),
            fotos: this.formBuilder.array(dados.fotos
                ? dados.fotos.map(function (x) {
                    return _this.formBuilder.group({
                        arquivoId: x.arquivoId,
                        arquivo: x.arquivo,
                        acao: '',
                    });
                })
                : [], Validators.required),
            modalidadeId: [dados.modalidadeId, Validators.required],
            numeroProcesso: [dados.numeroProcesso],
            instituicaoId: [dados.instituicaoId],
            bairroId: [dados.bairro ? dados.bairro.bairroId : null],
            municipioId: [dados.municipioId ? dados.municipioId : null],
            estadoId: [dados.estadoId ? dados.estadoId : null],
            endereco: [dados.endereco || null]
        });
        this.formulario.get('titulo').valueChanges.subscribe(function (value) {
            if (value) {
                _this.formulario
                    .get('titulo')
                    .setValue(value.toUpperCase(), { emitEvent: false });
            }
        });
        this.onChangeEstado();
        this.onChangeMunicipio();
        this.hasLocalidade.setValue(true);
    };
    UpdateImovelComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.formulario.valid) {
            Object.keys(this.formulario.controls).forEach(function (campo) {
                var controle = _this.formulario.get(campo);
                controle.markAsTouched();
            });
            this.notifierService.notify('error', 'Preencha todos os campos obrigatórios');
            return false;
        }
        var formulario = this.formulario.value;
        this.restangular
            .all('imovel')
            .customPUT(formulario)
            .subscribe(function (a) {
            _this.notifierService.notify('success', 'Imóvel Atualizado com sucesso');
            _this.router.navigate(['/imoveis']);
        }, function (error) {
            _this.notifierService.notify('error', 'Erro ao Atualizar o Imóvel!');
        });
    };
    UpdateImovelComponent.prototype.fileChangeEvent = function (fileInput) {
        var _this = this;
        this.imageError = null;
        var arrayImagens = fileInput.target.files.length;
        for (var i = 0; i < arrayImagens; i++) {
            this.arrayFotos.push(fileInput.target.files[i]);
        }
        this.arrayFotos.forEach(function (x) {
            if (x) {
                // Size Filter Bytes
                var max_size = 5242880;
                var allowed_types = ['image/png', 'image/jpeg'];
                var max_height_1 = 15200;
                var max_width_1 = 25600;
                if (x.size > max_size) {
                    _this.imageError = 'Maximum size allowed is 5Mb';
                    _this.arrayFotos = [];
                    return false;
                }
                // if (!_.includes(allowed_types, x.type)) {
                //   this.arrayFotos = [];
                //   this.imageError = 'Only Images are allowed ( JPG | PNG )';
                //   return false;
                // }
                var reader = new FileReader();
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function (rs) {
                        var img_height = rs.currentTarget['height'];
                        var img_width = rs.currentTarget['width'];
                        _this.arrayFotos = [];
                        if (img_height > max_height_1 && img_width > max_width_1) {
                            _this.imageError =
                                'Maximum dimentions allowed ' +
                                    max_height_1 +
                                    '*' +
                                    max_width_1 +
                                    'px';
                            return false;
                        }
                        else {
                            var imgBase64Path = e.target.result;
                            var arquivo = {
                                url: imgBase64Path,
                                nome: x.name,
                                base64: imgBase64Path,
                                tipo: x.type,
                                tamanho: x.size,
                            };
                            _this.arrayFotos = [];
                            _this.atualizarFoto(arquivo, _this.numeroAdcFoto);
                        }
                    };
                };
                reader.readAsDataURL(x);
            }
        });
    };
    UpdateImovelComponent.prototype.anexoChangeEvent = function (anexoInput) {
        var _this = this;
        this.fileToUpload = anexoInput.item(0);
        this.fileToUpload.name;
        this.fileToUpload.size;
        this.fileToUpload.type;
        var reader = new FileReader();
        reader.readAsDataURL(this.fileToUpload);
        reader.onload = function () {
            _this.anexosbase64 = reader.result;
            var arquivo = {
                arquivoId: 0,
                nome: _this.fileToUpload.name,
                base64: _this.anexosbase64,
                tipo: _this.fileToUpload.type,
                tamanho: _this.fileToUpload.size,
                dataCadastro: moment().utc().toISOString(),
            };
            _this.atualizarAnexo(arquivo, _this.numeroAdcAnexo);
        };
    };
    UpdateImovelComponent.prototype.adicionarCampo = function (imovelCampoId) {
        if (imovelCampoId === void 0) { imovelCampoId = null; }
        var campos = this.formulario.get('campos');
        campos.push(this.formBuilder.group({
            campoId: [imovelCampoId, Validators.required],
            valor: ['', Validators.required],
            acao: 'I',
        }));
    };
    UpdateImovelComponent.prototype.atualizarFoto = function (obj, i) {
        var fotos = this.formulario.get('fotos');
        if (i < 0) {
            fotos.insert(0, this.formBuilder.group({
                arquivo: obj,
                acao: 'I',
            }));
        }
        else {
            var valor = fotos.value[i];
            fotos.removeAt(i);
            fotos.insert(i, this.formBuilder.group({
                arquivo: obj,
                acao: 'I',
            }));
        }
    };
    UpdateImovelComponent.prototype.alterarFoto = function (i) {
        this.numeroAdcFoto = i;
        this.inputFotos.nativeElement.click();
    };
    UpdateImovelComponent.prototype.atualizarAnexo = function (obj, i) {
        var anexos = this.formulario.get('anexos');
        if (i < 0) {
            anexos.push(this.formBuilder.group({
                arquivo: obj,
                acao: 'I',
                nome: [obj.nome, Validators.required],
            }));
        }
        else {
            var valor = anexos.value[i];
            anexos.removeAt(i);
            anexos.insert(i, this.formBuilder.group({
                arquivo: obj,
                acao: 'A',
                nome: [obj.nome, Validators.required],
            }));
        }
    };
    UpdateImovelComponent.prototype.alterarAnexo = function (i) {
        this.numeroAdcAnexo = i;
        this.inputAnexos.nativeElement.click();
    };
    UpdateImovelComponent.prototype.filterList = function (campo) {
        var fotos = this.formulario.get(campo);
        return fotos.controls.filter(function (x) { return x.controls['acao'].value !== 'D'; });
    };
    UpdateImovelComponent.prototype.deleteCampo = function (indexCampo) {
        var campos = this.formulario.controls['campos'];
        var campo = campos.at(indexCampo);
        if (campo.controls['acao'].value !== 'I') {
            campo.controls['acao'].setValue('D');
        }
        else {
            campos.removeAt(indexCampo);
        }
    };
    UpdateImovelComponent.prototype.deleteAnexo = function (indexAnexo) {
        var anexos = this.formulario.controls['anexos'];
        var anexo = anexos.at(indexAnexo);
        if (anexo.controls['acao'].value !== 'I') {
            anexo.controls['acao'].setValue('D');
        }
        else {
            anexos.removeAt(indexAnexo);
        }
    };
    UpdateImovelComponent.prototype.deleteFoto = function (indexFoto) {
        var fotos = this.formulario.controls['fotos'];
        var foto = fotos.at(indexFoto);
        if (foto.controls['acao'].value !== 'I') {
            foto.controls['acao'].setValue('D');
        }
        else {
            fotos.removeAt(indexFoto);
        }
    };
    UpdateImovelComponent.prototype.onValueChangePraca = function (event, campo, i) {
        var pracas = this.formulario.get('pracas');
        var praca = pracas.at(i);
        praca.controls[campo].markAsTouched();
        praca.controls[campo].setValue(event);
    };
    UpdateImovelComponent.prototype.adicionarPraca = function () {
        var pracas = this.formulario.get('pracas');
        pracas.push(this.formBuilder.group({
            pracaId: [0],
            numero: [pracas.length + 1],
            data: [null, Validators.required],
            valor: [null, Validators.required],
        }));
    };
    UpdateImovelComponent.prototype.deletePraca = function (indexPraca) {
        var pracas = this.formulario.controls['pracas'];
        pracas.removeAt(indexPraca);
    };
    UpdateImovelComponent.prototype.verificaValidTouched = function (campo) {
        if (!this.formulario.get(campo).disabled) {
            return (!this.formulario.get(campo).valid && this.formulario.get(campo).touched);
        }
        return false;
    };
    UpdateImovelComponent.prototype.verificaValidList = function (campoArray, campo, i) {
        var lista = this.formulario.get(campoArray);
        var item = lista.controls[i];
        return !item.get(campo).valid;
    };
    UpdateImovelComponent.prototype.aplicaCssErro = function (campo) {
        return { 'has-error': this.verificaValidTouched(campo) };
    };
    UpdateImovelComponent.prototype.aplicaCssErroLista = function (campoArray, campo, i) {
        return { 'has-error': this.verificaValidList(campoArray, campo, i) };
    };
    return UpdateImovelComponent;
}());
export { UpdateImovelComponent };
