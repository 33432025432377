var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Imovel } from '../_models';
import { NotifierService } from 'angular-notifier';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
var RelatoriosImovelComponent = /** @class */ (function () {
    function RelatoriosImovelComponent(restangular, notifier, confirmation, fb, modalService, localeService) {
        this.restangular = restangular;
        this.notifier = notifier;
        this.confirmation = confirmation;
        this.fb = fb;
        this.modalService = modalService;
        this.localeService = localeService;
        this.loading = false;
        this.exportandoPdf = false;
        this.titulo = '';
        this.filtrando = false;
        this.filtrosAplicados = 0;
        this.imovelAlterado = [];
        this.imovelRemovido = [];
        this.bsValue = new Date();
        this.bsValue2 = new Date();
        this.maxDate = new Date();
        //localidade
        this.estados = [];
        this.municipios = [];
        this.bairros = [];
        this.groupByMunicipio = function (item) { return item.municipio.nome; };
        this.groupValueFn = function (_, children) { return ({
            name: children[0].municipio.nome,
        }); };
        this.localeService.use('pt-br');
    }
    RelatoriosImovelComponent.prototype.ngOnInit = function () {
        this.defaultFormValue = {
            estado: [''],
            municipio: [[]],
            bairro: [[]],
            zona: [''],
            titulo: [''],
            data: [],
            data2: [],
            imoveisActive: [true],
            origem: [[]],
            referencia: [''],
            valorInicial: '',
            valorFinal: '',
            pregao: [[]],
            tipo: [[]],
            modalidade: [[]],
            numProcesso: '',
        };
        this.filtro = this.fb.group(this.defaultFormValue);
        this.bsRangeValue = [this.bsValue, this.maxDate];
        this.bsRangeValue2 = [this.bsValue2, this.maxDate];
        this.getAllImoveis();
        this.getTipoImovel();
        this.getTipoPregao();
        this.getModalidade();
        this.getOrigem();
    };
    RelatoriosImovelComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
        this.getEstado();
    };
    RelatoriosImovelComponent.prototype.openModalEdit = function (template, item) {
        this.imovelEditando = __assign({}, item, { endereco: __assign({}, item.endereco), praca1: __assign({}, item.praca1), praca2: __assign({}, item.praca2) });
        if (this.imovelEditando) {
            this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
        }
    };
    RelatoriosImovelComponent.prototype.getEstado = function () {
        var _this = this;
        this.restangular
            .one('localidade/estado')
            .get()
            .subscribe(function (estados) {
            _this.estados = estados.data;
        });
        if (this.filtro.value.estado === null ||
            this.filtro.value.estado.length === 0) {
            this.filtro.get('municipio').disable();
            this.filtro.get('bairro').disable();
            return;
        }
        if (this.filtro.value.municipio === null ||
            this.filtro.value.municipio.length === 0) {
            this.filtro.get('bairro').disable();
            return;
        }
    };
    RelatoriosImovelComponent.prototype.onChangeEstado = function () {
        var _this = this;
        this.filtro.get('municipio').disable();
        if (this.filtro.value.estado === null ||
            this.filtro.value.estado.length === 0) {
            this.filtro.get('municipio').disable();
            this.filtro.get('bairro').disable();
            return;
        }
        this.restangular
            .one('localidade/municipio')
            .get({ estados: this.filtro.value.estado })
            .subscribe(function (municipios) {
            _this.municipios = municipios.data;
            _this.filtro.get('municipio').enable();
        });
    };
    RelatoriosImovelComponent.prototype.onChangeMunicipio = function () {
        var _this = this;
        var municipios = this.filtro.value.municipio;
        if (municipios === null || municipios.length === 0) {
            this.filtro.get('bairro').disable();
            return;
        }
        if (this.filtro.value.estado === null ||
            this.filtro.value.estado.length === 0) {
            this.filtro.get('estado').markAsTouched();
            this.filtro.get('municipio').disable();
            this.filtro.get('bairro').disable();
            return;
        }
        this.restangular
            .one('localidade/bairro')
            .get({ municipios: municipios })
            .subscribe(function (res) {
            var bairros = res.data;
            _this.bairros = bairros;
            _this.filtro.get('bairro').enable();
        });
    };
    RelatoriosImovelComponent.prototype.contarFiltros = function (values) {
        this.filtrosAplicados = Object.values(values).filter(function (value) {
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            return value;
        }).length;
    };
    RelatoriosImovelComponent.prototype.onChangeFilter = function () {
        this.imoveis = [];
        this.getAllImoveis();
        this.contarFiltros(this.filtro.value);
    };
    RelatoriosImovelComponent.prototype.getTipoImovel = function () {
        var _this = this;
        this.restangular
            .one('tipo')
            .get()
            .subscribe(function (response) {
            _this.tipoImovel = response.data;
        });
    };
    RelatoriosImovelComponent.prototype.getTipoPregao = function () {
        var _this = this;
        this.restangular
            .one('pregao')
            .get()
            .subscribe(function (response) {
            _this.tipoPregao = response.data;
        });
    };
    RelatoriosImovelComponent.prototype.getModalidade = function () {
        var _this = this;
        this.restangular
            .one('modalidade')
            .get()
            .subscribe(function (response) {
            _this.modalidade = response.data;
        });
    };
    RelatoriosImovelComponent.prototype.getOrigem = function () {
        var _this = this;
        this.restangular
            .one('imovel/origem')
            .get()
            .subscribe(function (response) {
            _this.origem = response.data;
        });
    };
    RelatoriosImovelComponent.prototype.downloadRelatorio = function () {
        var _this = this;
        var dataInicialPrimeiraPraca = null;
        var dataFinalPrimeiraPraca = null;
        var dataInicialSegundaPraca = null;
        var dataFinalSegundaPraca = null;
        var datas = this.filtro.controls['data'].value;
        if (datas !== null) {
            dataInicialPrimeiraPraca = moment(datas[0]).toISOString();
            dataFinalPrimeiraPraca = moment(datas[1]).toISOString();
        }
        var datas2 = this.filtro.controls['data2'].value;
        if (datas2 !== null) {
            dataInicialSegundaPraca = moment(datas2[0]).toISOString();
            dataFinalSegundaPraca = moment(datas2[1]).toISOString();
        }
        var filtro = this.filtro.value;
        var url = 'imovel/relatorio/pdf';
        var extensao = '.pdf';
        this.exportandoPdf = true;
        this.restangular
            .all(url)
            .withHttpConfig({ responseType: 'blob' })
            .post({
            titulo: filtro.titulo,
            bairros: filtro.bairro,
            estado: filtro.estado == '' ? null : filtro.estado,
            municipios: filtro.municipio,
            zona: filtro.zona,
            dataInicialPrimeiraPraca: dataInicialPrimeiraPraca,
            dataFinalPrimeiraPraca: dataFinalPrimeiraPraca,
            dataInicialSegundaPraca: dataInicialSegundaPraca,
            dataFinalSegundaPraca: dataFinalSegundaPraca,
            origem: filtro.origem,
            id: filtro.referencia == '' ? null : filtro.referencia,
            ImoveisActive: filtro.imoveisActive,
            valorInicial: filtro.valorInicial == '' || filtro.valorInicial == 'NaN'
                ? null
                : filtro.valorInicial,
            valorFinal: filtro.valorFinal == '' || filtro.valorFinal == 'NaN'
                ? null
                : filtro.valorFinal,
            tipo: filtro.tipo,
            modalidade: filtro.modalidade,
            pregao: filtro.pregao,
            processo: filtro.numProcesso,
            ImoveisRemovidos: this.imovelRemovido,
            ImoveisAlterados: this.imovelAlterado,
        })
            .subscribe(function (response) {
            _this.exportandoPdf = false;
            var blob = response;
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "RelatorioImoveis_" + moment().unix() + extensao;
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            }, 100);
        }, function (err) {
            _this.exportandoPdf = false;
            _this.notifier.notify('error', 'Erro ao gerar o relatorio de imóveis');
        });
    };
    RelatoriosImovelComponent.prototype.getAllImoveis = function () {
        var _this = this;
        var dataInicialPrimeiraPraca = '';
        var dataFinalPrimeiraPraca = '';
        var dataInicialSegundaPraca = '';
        var dataFinalSegundaPraca = '';
        var datas = this.filtro.controls['data'].value;
        if (datas !== null) {
            dataInicialPrimeiraPraca = moment(datas[0]).toISOString();
            dataFinalPrimeiraPraca = moment(datas[1]).toISOString();
        }
        var datas2 = this.filtro.controls['data2'].value;
        if (datas2 !== null) {
            dataInicialSegundaPraca = moment(datas2[0]).toISOString();
            dataFinalSegundaPraca = moment(datas2[1]).toISOString();
        }
        this.loading = true;
        var filtro = this.filtro.getRawValue();
        this.restangular
            .one('imovel/relatorio')
            .get({
            titulo: filtro.titulo,
            bairros: filtro.bairro,
            estado: filtro.estado,
            municipios: filtro.municipio,
            zona: filtro.zona,
            dataInicialPrimeiraPraca: dataInicialPrimeiraPraca,
            dataFinalPrimeiraPraca: dataFinalPrimeiraPraca,
            dataInicialSegundaPraca: dataInicialSegundaPraca,
            dataFinalSegundaPraca: dataFinalSegundaPraca,
            origem: filtro.origem,
            id: filtro.referencia,
            ImoveisActive: filtro.imoveisActive,
            valorInicial: Number.isNaN(filtro.valorInicial)
                ? ''
                : filtro.valorInicial,
            valorFinal: Number.isNaN(filtro.valorFinal) ? '' : filtro.valorFinal,
            tipo: filtro.tipo,
            modalidade: filtro.modalidade,
            pregao: filtro.pregao,
            processo: filtro.numProcesso,
        })
            .subscribe(function (res) {
            _this.loading = false;
            _this.imoveis = res.data;
        }, function (err) {
            _this.loading = false;
        });
    };
    RelatoriosImovelComponent.prototype.removerDaLista = function (Id) {
        var index = this.imoveis.findIndex(function (imovel) { return imovel.id === Id; });
        if (index !== -1) {
            this.imoveis.splice(index, 1);
            this.imovelRemovido.push(Id);
        }
    };
    RelatoriosImovelComponent.prototype.salvarEdicao = function (objeto) {
        var existeEdicao = this.imovelAlterado.some(function (imovel) { return imovel.id === objeto.id; });
        if (existeEdicao) {
            this.imovelAlterado = this.imovelAlterado.filter(function (imovel) { return imovel.id !== objeto.id; });
        }
        this.imovelAlterado.push(objeto);
        var index = this.imoveis.findIndex(function (imovel) { return imovel.id === objeto.id; });
        if (index !== -1) {
            this.imoveis[index] = objeto;
        }
        this.modalRef.hide();
    };
    RelatoriosImovelComponent.prototype.removerFiltros = function () {
        this.filtrosAplicados = 0;
        this.filtro = this.fb.group(this.defaultFormValue);
        this.imoveis = [];
        this.getAllImoveis();
    };
    return RelatoriosImovelComponent;
}());
export { RelatoriosImovelComponent };
