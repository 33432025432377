import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'app-create-checklistCampo',
  templateUrl: './create-checklist-campo.component.html',
  styleUrls: ['./create-checklist-campo.component.scss']
})
export class CreateCheckListCampoComponent implements OnInit {

  formulario: FormGroup;
  tipos: any[] = [];

  constructor(private restAngular: Restangular, private notifier: NotifierService, private router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute) 
  { 
  }

  ngOnInit() {

    let sugestaoOrdem: number;

    this.route.queryParams.subscribe(params => {
      sugestaoOrdem = Number(params['lastOrdem']) + 1;
    });

    this.restAngular.one('checkListCampo/tipos').get().subscribe((res) => {
      this.tipos = res;
    }, (err) => {
      this.notifier.notify('error', 'erro ao obter tipos de campos');
    });

    this.formulario = this.formBuilder.group({
      ordem: [sugestaoOrdem, Validators.required],
      label: ['', Validators.required],
      tipo: [null, Validators.required],
      campoPdf: [false]
    });
  }

  onSubimit(){
    this.restAngular.all('checklistCampo').post(this.formulario.value).subscribe(
      () => {
        this.notifier.notify('success', 'checklistCampo cadastrado com sucesso');
        this.router.navigate(['/checklist-campos']);
      },
      () => {
        this.notifier.notify('error', 'Erro ao cadastrar checklistCampo');
      }
    );
  }

  verificaValidTouched(campo) {
    return !this.formulario.get(campo).valid && this.formulario.get(campo).touched;
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) }
  }
}
