var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import { forkJoin } from 'rxjs';
var UpdateUsuariosComponent = /** @class */ (function () {
    function UpdateUsuariosComponent(router, formBuilder, restangular, notifierService, routerLink) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.restangular = restangular;
        this.notifierService = notifierService;
        this.routerLink = routerLink;
        this.perfis = [];
        this.formulario = this.formBuilder.group({
            nome: [null, [Validators.required, Validators.minLength(3)]],
            perfilId: [[], Validators.required],
            orgao: [''],
            matricula: [''],
            login: ['', Validators.required],
            senha: [null],
            assinatura: this.formBuilder.group({
                arquivoId: [0],
                nome: [''],
                base64: [''],
                tipo: [''],
                tamanho: [0],
            }),
        });
    }
    UpdateUsuariosComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.usuarioId = this.routerLink.snapshot.params['id'];
        forkJoin([
            this.restangular.one('/perfil').get().pipe(),
            this.restangular.one('/usuario', this.usuarioId).get().pipe(),
        ]).subscribe(function (allResp) {
            _this.perfis = allResp[0].data;
            _this.updateForm(allResp[1].data);
        });
    };
    UpdateUsuariosComponent.prototype.updateForm = function (usuario) {
        this.formulario.patchValue({
            nome: usuario.nome,
            perfilId: usuario.perfilId,
            orgao: usuario.orgao || '',
            matricula: usuario.matricula || '',
            login: usuario.login || '',
            assinatura: usuario.assinatura != null
                ? {
                    arquivoId: usuario.assinatura.arquivoId,
                    nome: usuario.assinatura.nome,
                    base64: usuario.assinatura.base64,
                    tipo: usuario.assinatura.tipo,
                    tamanho: usuario.assinatura.tamanho,
                }
                : {
                    arquivoId: 0,
                    nome: '',
                    base64: '',
                    tipo: '',
                    tamanho: 0,
                },
        });
        this.isImageSaved = usuario.assinatura != null ? true : false;
        this.cardImageBase64 =
            usuario.assinatura != null ? usuario.assinatura.url : null;
    };
    UpdateUsuariosComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.formulario.valid) {
            Object.keys(this.formulario.controls).forEach(function (campo) {
                var controle = _this.formulario.get(campo);
                controle.markAsTouched();
            });
            this.notifierService.notify('error', 'Preencha todos os campos obrigatórios');
            return;
        }
        this.restangular
            .all('usuario')
            .customPUT(__assign({}, this.formulario.value, { usuarioId: this.usuarioId }))
            .subscribe(function (a) {
            _this.notifierService.notify('success', 'Usuário criado com sucesso');
            _this.router.navigateByUrl('/usuarios');
        }, function (error) {
            var errors = error.data.Errors;
            for (var k in errors) {
                if (k.toLowerCase() === 'exception') {
                    _this.notifierService.notify('error', 'Erro ao atualizar usuário');
                }
                else {
                    _this.notifierService.notify('error', errors[k]);
                }
            }
            Object.keys(_this.formulario.controls).forEach(function (campo) {
                var controle = _this.formulario.get(campo);
                controle.markAsTouched();
            });
        });
    };
    UpdateUsuariosComponent.prototype.verificaValidTouched = function (campo) {
        return (!this.formulario.get(campo).valid && this.formulario.get(campo).touched);
    };
    UpdateUsuariosComponent.prototype.aplicaCssErro = function (campo) {
        return {
            'has-error': this.verificaValidTouched(campo),
        };
    };
    UpdateUsuariosComponent.prototype.onValueChange = function (event, campo) {
        this.formulario.get(campo).markAsTouched();
        this.formulario.get(campo).setValue(event);
    };
    UpdateUsuariosComponent.prototype.toggleFieldTextType = function () {
        this.fieldTextType = !this.fieldTextType;
    };
    UpdateUsuariosComponent.prototype.fileChangeEvent = function (fileInput) {
        var _this = this;
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            var max_size = 5242880;
            var allowed_types = ['image/png', 'image/jpeg'];
            var max_height_1 = 15200;
            var max_width_1 = 25600;
            if (fileInput.target.files[0].size > max_size) {
                this.imageError = 'O tamanho máximo permitido é 5Mb';
                return false;
            }
            if (!allowed_types.includes(fileInput.target.files[0].type)) {
                this.imageError = 'Somente imagens são permitidas ( JPG | PNG )';
                return false;
            }
            var reader = new FileReader();
            reader.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function (rs) {
                    var img_height = rs.currentTarget['height'];
                    var img_width = rs.currentTarget['width'];
                    if (img_height > max_height_1 && img_width > max_width_1) {
                        _this.imageError =
                            'Tamanho máximo permitido ' + max_height_1 + '*' + max_width_1 + 'px';
                        return false;
                    }
                    else {
                        var imgBase64Path = e.target.result;
                        _this.cardImageBase64 = imgBase64Path;
                        _this.isImageSaved = true;
                        var assinatura = _this.formulario.get('assinatura');
                        assinatura.get('base64').setValue(imgBase64Path);
                        assinatura.get('nome').setValue(fileInput.target.files[0].name);
                        assinatura.get('tamanho').setValue(fileInput.target.files[0].size);
                        assinatura.get('tipo').setValue(fileInput.target.files[0].type);
                    }
                };
            };
            reader.readAsDataURL(fileInput.target.files[0]);
        }
    };
    UpdateUsuariosComponent.prototype.removeImage = function (input) {
        this.cardImageBase64 = null;
        this.isImageSaved = false;
        input.value = '';
    };
    return UpdateUsuariosComponent;
}());
export { UpdateUsuariosComponent };
