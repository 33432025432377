import { OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
var ImovelComponent = /** @class */ (function () {
    function ImovelComponent(restangular, notifier, confirmation, fb, modalService, localeService) {
        var _this = this;
        this.restangular = restangular;
        this.notifier = notifier;
        this.confirmation = confirmation;
        this.fb = fb;
        this.modalService = modalService;
        this.localeService = localeService;
        this.loading = false;
        this.exportando = false;
        this.exportandoPdf = false;
        this.titulo = '';
        this.filtrando = false;
        this.filtrosAplicados = 0;
        this.bsValue = new Date();
        this.bsValue2 = new Date();
        this.maxDate = new Date();
        this.origens = [];
        //localidade
        this.estados = [];
        this.municipios = [];
        this.bairros = [];
        this.localeService.use('pt-br');
        this.filtro = this.fb.group({
            estado: [null],
            municipio: [''],
            bairro: [''],
            zona: [''],
            titulo: [''],
            data: [],
            data2: [],
            imoveisActive: [true],
            origem: [''],
            referencia: [''],
            valorInicial: '',
            valorFinal: '',
            pregao: [''],
            tipo: [''],
            modalidade: [''],
            numProcesso: '',
        });
        this.mask = [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            '.',
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ];
        this.filtro.valueChanges.subscribe(function (value) {
            _this.onChangeFilter();
        });
    }
    ImovelComponent.prototype.ngOnInit = function () {
        this.defaultFormValue = {
            estado: [null],
            municipio: [''],
            bairro: [[]],
            zona: [''],
            titulo: [''],
            data: [],
            data2: [],
            imoveisActive: [true],
            origem: [[]],
            referencia: [''],
            valorInicial: '',
            valorFinal: '',
            pregao: [[]],
            tipo: [[]],
            modalidade: [[]],
            numProcesso: '',
        };
        this.filtro = this.fb.group(this.defaultFormValue);
        this.bsRangeValue = [this.bsValue, this.maxDate];
        this.bsRangeValue2 = [this.bsValue2, this.maxDate];
        this.getAllImoveis();
        this.getTipoImovel();
        this.getTipoPregao();
        this.getModalidade();
        this.getOrigens();
    };
    ImovelComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
        this.getEstado();
    };
    ImovelComponent.prototype.getEstado = function () {
        var _this = this;
        this.restangular
            .one('localidade/estado')
            .get()
            .subscribe(function (estados) {
            _this.estados = estados.data;
        });
        if (this.filtro.value.estado === null ||
            this.filtro.value.estado.length === 0) {
            this.filtro.get('municipio').disable();
            this.filtro.get('bairro').disable();
            return;
        }
        if (this.filtro.value.municipio === null ||
            this.filtro.value.municipio.length === 0) {
            this.filtro.get('bairro').disable();
            return;
        }
    };
    ImovelComponent.prototype.onChangeEstado = function () {
        var _this = this;
        this.filtro.get('municipio').disable();
        if (this.filtro.value.estado === null ||
            this.filtro.value.estado.length === 0) {
            this.filtro.get('municipio').disable();
            this.filtro.get('bairro').disable();
            return;
        }
        this.restangular
            .one('localidade/municipio')
            .get({ estados: this.filtro.value.estado })
            .subscribe(function (municipios) {
            _this.municipios = municipios.data;
            _this.filtro.get('municipio').enable();
        });
    };
    ImovelComponent.prototype.onChangeMunicipio = function () {
        var _this = this;
        var municipios = this.filtro.value.municipio;
        if (municipios === null || municipios.length === 0) {
            this.filtro.get('bairro').disable();
            return;
        }
        if (this.filtro.value.estado === null ||
            this.filtro.value.estado.length === 0) {
            this.filtro.get('estado').markAsTouched();
            this.filtro.get('municipio').disable();
            this.filtro.get('bairro').disable();
            return;
        }
        this.restangular
            .one('localidade/bairro')
            .get({ municipios: municipios })
            .subscribe(function (res) {
            var bairros = res.data;
            _this.bairros = bairros;
            _this.filtro.get('bairro').enable();
        });
    };
    ImovelComponent.prototype.contarFiltros = function (values) {
        this.filtrosAplicados = Object.entries(values).filter(function (_a) {
            var key = _a[0], value = _a[1];
            if (key !== 'imoveisActive') {
                if (Array.isArray(value)) {
                    return value.length > 0;
                }
                return value;
            }
            return false;
        }).length;
    };
    ImovelComponent.prototype.onChangeFilter = function () {
        this.imoveis = [];
        this.getAllImoveis();
        this.contarFiltros(this.filtro.value);
    };
    ImovelComponent.prototype.getTipoImovel = function () {
        var _this = this;
        this.restangular
            .one('tipo')
            .get()
            .subscribe(function (response) {
            _this.tipoImovel = response.data;
        });
    };
    ImovelComponent.prototype.getTipoPregao = function () {
        var _this = this;
        this.restangular
            .one('pregao')
            .get()
            .subscribe(function (response) {
            _this.tipoPregao = response.data;
        });
    };
    ImovelComponent.prototype.getModalidade = function () {
        var _this = this;
        this.restangular
            .one('modalidade')
            .get()
            .subscribe(function (response) {
            _this.modalidade = response.data;
        });
    };
    ImovelComponent.prototype.getOrigens = function () {
        var _this = this;
        this.restangular
            .one('imovel/origem')
            .get()
            .subscribe(function (response) {
            _this.origens = response.data.map(function (x) {
                return { value: x };
            });
        });
    };
    ImovelComponent.prototype.getAllImoveis = function () {
        var _this = this;
        var dataInicialPrimeiraPraca = '';
        var dataFinalPrimeiraPraca = '';
        var dataInicialSegundaPraca = '';
        var dataFinalSegundaPraca = '';
        var datas = this.filtro.controls['data'].value;
        if (datas !== null) {
            dataInicialPrimeiraPraca = moment(datas[0]).toISOString();
            dataFinalPrimeiraPraca = moment(datas[1]).toISOString();
        }
        var datas2 = this.filtro.controls['data2'].value;
        if (datas2 !== null) {
            dataInicialSegundaPraca = moment(datas2[0]).toISOString();
            dataFinalSegundaPraca = moment(datas2[1]).toISOString();
        }
        this.loading = true;
        var filtro = this.filtro.getRawValue();
        this.restangular
            .one('imovel')
            .get({
            titulo: filtro.titulo,
            bairros: filtro.bairro,
            estado: filtro.estado || '',
            municipio: filtro.municipio,
            zona: filtro.zona,
            dataInicialPrimeiraPraca: dataInicialPrimeiraPraca,
            dataFinalPrimeiraPraca: dataFinalPrimeiraPraca,
            dataInicialSegundaPraca: dataInicialSegundaPraca,
            dataFinalSegundaPraca: dataFinalSegundaPraca,
            origem: filtro.origem,
            id: filtro.referencia,
            ImoveisActive: filtro.imoveisActive,
            valorInicial: filtro.valorInicial,
            valorFinal: filtro.valorFinal,
            tipo: filtro.tipo,
            modalidade: filtro.modalidade,
            pregao: filtro.pregao,
            processo: this.removerMask(filtro.numProcesso),
        })
            .subscribe(function (res) {
            _this.loading = false;
            _this.imoveis = res.data.imoveis;
            //this.origens = res.data.origens;
        }, function (err) {
            _this.loading = false;
        });
    };
    ImovelComponent.prototype.delete = function (imovelId) {
        var _this = this;
        this.confirmation
            .create('Atenção', 'Deseja realmente excluir o imóvel?')
            .subscribe(function (ans) {
            if (ans.resolved) {
                _this.restangular
                    .one('imovel', imovelId)
                    .remove()
                    .subscribe(function (res) {
                    _this.getAllImoveis();
                }, function (err) {
                    _this.notifier.notify('error', 'Erro ao excluir imóvel');
                });
            }
        });
    };
    ImovelComponent.prototype.downloadRelatorio = function (tipo) {
        var _this = this;
        var dataInicialPrimeiraPraca = '';
        var dataFinalPrimeiraPraca = '';
        var dataInicialSegundaPraca = '';
        var dataFinalSegundaPraca = '';
        var datas = this.filtro.controls['data'].value;
        if (datas !== null) {
            dataInicialPrimeiraPraca = moment(datas[0]).toISOString();
            dataFinalPrimeiraPraca = moment(datas[1]).toISOString();
        }
        var datas2 = this.filtro.controls['data2'].value;
        if (datas2 !== null) {
            dataInicialSegundaPraca = moment(datas2[0]).toISOString();
            dataFinalSegundaPraca = moment(datas2[1]).toISOString();
        }
        var filtro = this.filtro.getRawValue();
        var url = tipo == 'PDF' ? 'imovel/relatorio/pdf' : 'imovel/relatorio/excel';
        var extensao = tipo == 'PDF' ? '.pdf' : '.xlsx';
        this.exportando = tipo == 'EXCEL';
        this.exportandoPdf = tipo == 'PDF';
        this.restangular
            .one(url)
            .withHttpConfig({ responseType: 'blob' })
            .get({
            titulo: filtro.titulo,
            bairros: filtro.bairro,
            estado: filtro.estado,
            municipio: filtro.municipio,
            zona: filtro.zona,
            dataInicialPrimeiraPraca: dataInicialPrimeiraPraca,
            dataFinalPrimeiraPraca: dataFinalPrimeiraPraca,
            dataInicialSegundaPraca: dataInicialSegundaPraca,
            dataFinalSegundaPraca: dataFinalSegundaPraca,
            origem: filtro.origem,
            id: filtro.referencia,
            ImoveisActive: filtro.imoveisActive,
            valorInicial: filtro.valorInicial,
            valorFinal: filtro.valorFinal,
            tipo: filtro.tipo,
            modalidade: filtro.modalidade,
            pregao: filtro.pregao,
            processo: this.removerMask(filtro.numProcesso),
        })
            .subscribe(function (response) {
            _this.exportando = false;
            _this.exportandoPdf = false;
            var blob = response;
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "RelatorioImoveis_" + moment().unix() + extensao;
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            }, 100);
        }, function (err) {
            _this.exportando = false;
            _this.exportandoPdf = false;
            _this.notifier.notify('error', 'Erro ao gerar o relatorio de imóveis');
        });
    };
    ImovelComponent.prototype.removerMask = function (value) {
        return value.replace(/\D/g, '');
    };
    ImovelComponent.prototype.removerFiltros = function () {
        this.filtrosAplicados = 0;
        this.filtro = this.fb.group(this.defaultFormValue);
        this.imoveis = [];
        this.getAllImoveis();
    };
    return ImovelComponent;
}());
export { ImovelComponent };
