import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotifierService } from 'angular-notifier';
import { Endereco, TipoPessoa } from 'app/views/_models';
import { ConsultaCepService } from 'app/views/usuarios/shared/consulta-cep/consulta-cep.service';
import { Restangular } from 'ngx-restangular';
import * as moment from 'moment';

@Component({
  selector: 'app-create-cliente',
  templateUrl: './create-cliente.component.html',
  styleUrls: ['./create-cliente.component.scss'],
})
export class CreateClienteComponent implements OnInit {
  @ViewChild('inputDocumentos') inputDocumentos: ElementRef;

  formulario: FormGroup;
  tiposPessoa: TipoPessoa[] = [];

  //masks
  public mask: Array<string | RegExp>;
  public maskCep: Array<string | RegExp>;
  public maskCnpj: Array<string | RegExp>;
  public maskCpf: (string | RegExp)[];
  public maskRg: (string | RegExp)[];

  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];

  //documentos
  documentosbase64: any;
  documentosnome: any;
  documentostamanho: any;
  documentostipo: any;
  numeroAdcDocumento: number;
  arrayDocumento = [];
  fileToUpload: File | null = null;
  tipodocumento: [];

  hasLocalidadeInteresse = this.formBuilder.control(true);
  hasEndereco = this.formBuilder.control(false);
  bairrosAgrupados: { label: string; children: any }[];
  salvando = false;

  constructor(
    private formBuilder: FormBuilder,
    private restangular: Restangular,
    private notifierService: NotifierService,
    private router: Router,
    private cepService: ConsultaCepService
  ) {
    this.mask = [
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
    this.maskCep = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.maskCpf = [
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
    this.maskCnpj = [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
    this.maskRg = [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
    ];
  }

  ngOnInit() {
    this.getTipoDocumento();

    this.formulario = this.formBuilder.group({
      nome: ['', Validators.required],
      rg: [''],
      nomeContato: [''],
      email: [''],
      telefone: [''],
      celular: [''],
      tipoPessoaId: [1, Validators.required],
      valorInicial: [0],
      valorFinal: [0],
      cpfCnpj: ['', Validators.required],
      bairroId: [null],
      municipioId: [null],
      estadoId: [null],
      localidadesInteresse: this.formBuilder.array([]),
      nacionalidade: [''],
      estadoCivil: [4],
      profissao: [''],
      documentos: this.formBuilder.array([]),
      observacao: [''],
    });

    this.restangular
      .one('cliente/tipoPessoa')
      .get()
      .subscribe((res: any) => {
        this.tiposPessoa = res.data;
      });

    this.restangular
      .one('localidade/estado')
      .get()
      .subscribe((estados) => {
        this.estados = estados.data;
        this.formulario.get('municipioId').disable();
        this.formulario.get('bairroId').disable();
      });
  }

  addEndereco() {
    if (this.hasEndereco) {
      this.formulario.addControl(
        'endereco',
        this.formBuilder.group({
          enderecoId: [0],
          cep: [''],
          numero: [''],
          complemento: [''],
          bairro: [''],
          cidade: [''],
          estado: [''],
          logradouro: [''],
        })
      );
    } else {
      this.formulario.removeControl('endereco');
    }
  }

  onChangeEstado() {
    this.formulario.get('municipioId').disable();

    if (this.formulario.value.estadoId === null) {
      this.formulario.get('municipioId').disable();
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/municipio')
      .get({ estadoId: this.formulario.value.estadoId })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.formulario.get('municipioId').enable();
      });
  }

  onChangeMunicipio() {
    const municipioId = this.formulario.value.municipioId;

    if (municipioId === null) {
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/bairro')
      .get({ municipioId })
      .subscribe((res) => {
        const bairros = res.data;

        // Organizar os bairros por zona usando reduce
        const bairrosPorZona = bairros.reduce((acc, bairro) => {
          const zona = bairro.zona || 'Sem Zona';

          if (!acc[zona]) {
            acc[zona] = [];
          }
          acc[zona].push({ bairroId: bairro.bairroId, nome: bairro.nome });
          return acc;
        }, {});

        // Converter para o formato desejado
        const bairrosFormatados = Object.keys(bairrosPorZona).map((zona) => {
          return {
            label: zona,
            children: bairrosPorZona[zona],
          };
        });

        // Atualizar os bairros organizados por zona
        this.bairrosAgrupados = bairrosFormatados;
        this.bairros = bairros;
        this.formulario.get('bairroId').enable();
      });
  }

  addLocalidadeInteresse() {
    const localidadesInteresse = this.formulario.get(
      'localidadesInteresse'
    ) as FormArray;

    if (
      localidadesInteresse.controls.filter(
        (x) => x.value.bairroId === this.formulario.value.bairroId
      ).length > 0
    ) {
      this.notifierService.notify('error', 'Localidade já adicionada');
      return;
    }

    localidadesInteresse.push(
      this.formBuilder.group({
        bairroId: [this.formulario.value.bairroId],
        bairro: [
          this.bairros.find(
            (bairro) => bairro.bairroId === this.formulario.value.bairroId
          ).nome,
        ],
        municipio: [
          this.municipios.find(
            (municipio) =>
              municipio.municipioId === this.formulario.value.municipioId
          ).nome,
        ],
        estado: [
          this.estados.find(
            (estado) => estado.estadoId === this.formulario.value.estadoId
          ).nome,
        ],
      })
    );

    this.formulario.get('bairroId').reset();
  }

  removeLocalidadeInteresse(index) {
    const localidadesInteresse = this.formulario.get(
      'localidadesInteresse'
    ) as FormArray;
    localidadesInteresse.removeAt(index);
  }

  onSubimit() {
    if (!this.formulario.valid) {
      if (!this.formulario.get('documentos').valid) {
        this.notifierService.notify(
          'error',
          'Preencha o campo tipo do documento'
        );
        return false;
      }
      Object.keys(this.formulario.controls).forEach((campo) => {
        const controle = this.formulario.get(campo);
        controle.markAsTouched();
      });
      this.notifierService.notify(
        'error',
        'Preencha todos os campos obrigatórios'
      );
      return false;
    }
    this.salvando = true;

    const body = this.formulario.getRawValue();
    body.estadoCivil = Number(body.tipoPessoaId);

    if (isNaN(body.valorInicial)) body.valorInicial = 0;
    if (isNaN(body.valorFinal)) body.valorFinal = 0;

    body.localidadesInteresse = body.localidadesInteresse.map(
      (x) => x.bairroId
    );

    this.restangular
      .all('cliente')
      .post(body)
      .subscribe(
        () => {
          this.salvando = false;
          this.notifierService.notify(
            'success',
            'Cliente cadastrado com sucesso!'
          );
          this.router.navigate(['/clientes']);
        },
        () => {
          this.salvando = false;
          this.notifierService.notify(
            'error',
            'Não foi possível cadastrar o cliente!'
          );
        }
      );
  }

  documentoChangeEvent(documentoInput: FileList) {
    this.fileToUpload = documentoInput.item(0);
    this.fileToUpload.name;
    this.fileToUpload.size;
    this.fileToUpload.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      this.documentosbase64 = reader.result;
      const arquivo = this.formBuilder.group({
        arquivoId: 0,
        nome: [this.fileToUpload.name, Validators.required],
        base64: this.documentosbase64,
        tipo: this.fileToUpload.type,
        tamanho: this.fileToUpload.size,
        dataCadastro: moment().utc().toISOString(),
      });

      this.atualizarDocumento(arquivo, this.numeroAdcDocumento);
    };
  }

  atualizarDocumento(obj, i) {
    const documentos = this.formulario.get('documentos') as FormArray;

    if (i < 0) {
      documentos.push(
        this.formBuilder.group({
          arquivo: obj,
          acao: 'I',
          tipoDocumentoId: [null, Validators.required],
        })
      );
    }
  }

  alterarDocumento(i) {
    this.numeroAdcDocumento = i;
    this.inputDocumentos.nativeElement.click();
  }

  deleteDocumento(indexDocumento: number) {
    const documentos = this.formulario.controls['documentos'] as FormArray;
    const documento = documentos.at(indexDocumento) as FormGroup;
    if (documento.controls['acao'].value !== 'I') {
      documento.controls['acao'].setValue('D');
    } else {
      documentos.removeAt(indexDocumento);
    }
  }

  getTipoDocumento() {
    this.restangular
      .one('tipodocumento')
      .get()
      .subscribe(
        (response) => {
          this.tipodocumento = response.data;
        },
        () => {
          this.notifierService.notify(
            'error',
            'Não foi possível carregar os tipos de documentos'
          );
        }
      );
  }

  filterList(campo: string) {
    const documentos = this.formulario.get(campo) as FormArray;
    return documentos.controls.filter(
      (x) => (x as FormGroup).controls['acao'].value !== 'D'
    );
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'has-error': this.verificaValidTouched(campo) };
  }

  consultaCEP() {
    const cep = this.formulario.get('endereco.cep').value;

    if (cep != null && cep !== '') {
      this.cepService
        .consultaCEP(cep)
        .subscribe((dados) => this.populaDadosForm(dados));
    }
  }

  populaDadosForm(dados: any) {
    this.formulario.patchValue({
      endereco: {
        logradouro: dados.logradouro,
        complemento: dados.complemento,
        bairro: dados.bairro,
        cidade: dados.localidade,
        estado: dados.uf,
      },
    });
  }
}
